import axios from "axios";
import { pick } from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Flex from "../../../components/Flex";
import NoAuthDesktopWrapper from "../../../components/Pages/NoAuthWrapper/noAuthDesktopWrapper";
import NoAuthMobileWrapper from "../../../components/Pages/NoAuthWrapper/noAuthMobileWrapper";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../context/Profile/hooks/useProfileDispatch";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import { tAzureAccount } from "../../../models/microsoftAzure";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { tCountryCode } from "../../../utils/countries";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import StepsHeader from "./stepsHeader";

type tData = {
  email: string;
  termsAccepted: boolean;
  azure: tAzureAccount | null;

  name: string;
  password: string;
  phoneNumber: string;
  country: tCountryCode;
  tourismRegisterType: string;
  tourismRegisterNumber: string;

  accommodationName: string;
  accommodationType: string;
  accommodationNumberOfUnits: number;
  accommodationWebsite: string;
  accommodationAddress: string;
  accommodationEmail: string;
  accommodationPhoneNumber: string;
  accommodationPostalCode: string;
  accommodationDistrict: string;
  accommodationCounty: string;
};

export type tRegisterState2 = {
  step: number;
  data: tData;
  errors: Record<keyof tData, string>;
};

const Register: React.FC = () => {
  const { isLaptop } = useWindowSizeState();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const [state2, setState2] = useState<tRegisterState2>({
    step: 0,
    data: {
      email: "",
      termsAccepted: false,

      azure: null,

      name: "",
      country: "PT",
      password: "",
      phoneNumber: "",
      tourismRegisterType: "",
      tourismRegisterNumber: "",

      accommodationName: "",
      accommodationType: "",
      accommodationNumberOfUnits: 0,
      accommodationWebsite: "",
      accommodationAddress: "",
      accommodationEmail: "",
      accommodationCounty: "",
      accommodationDistrict: "",
      accommodationPhoneNumber: "",
      accommodationPostalCode: "",
    },
    errors: {
      email: "",
      termsAccepted: "",

      azure: "",

      name: "",
      country: "",
      password: "",
      phoneNumber: "",
      tourismRegisterType: "",
      tourismRegisterNumber: "",

      accommodationName: "",
      accommodationType: "",
      accommodationNumberOfUnits: "",
      accommodationWebsite: "",
      accommodationAddress: "",
      accommodationEmail: "",
      accommodationCounty: "",
      accommodationDistrict: "",
      accommodationPhoneNumber: "",
      accommodationPostalCode: "",
    },
  });
  const { setProfile } = useProfileDispatch();

  const { step } = state2;

  const setStep = (step: tRegisterState2["step"]) => {
    setState2((prev) => ({ ...prev, step }));
  };

  const handleChange = (key: keyof tRegisterState2["data"], value: any) => {
    setState2({
      ...state2,
      data: { ...state2.data, [key]: value },
      errors: { ...state2.errors, [key]: "" },
    });
  };

  const handleErrors = (errors: Partial<tRegisterState2["errors"]>) => {
    setState2({ ...state2, errors: { ...state2.errors, ...errors } });
  };

  const desktopStepRenderer = (
    children: JSX.Element,
    gotoSignin: boolean = true
  ) => {
    return (
      <Flex middle column gap={16}>
        <Flex
          column
          center
          style={{
            backgroundColor: COLORS.light_white,
            borderRadius: "16px",
            padding: "32px 64px",
            minWidth: "640px",
            maxWidth: "640px",
          }}
          gap={24}
        >
          <StepsHeader
            current={step}
            onClickStep={(step: number) => {
              setStep(step);
            }}
          />
          {children}
        </Flex>
        {gotoSignin && (
          <Flex row gap={4} center>
            <InterTag
              color={COLORS.white}
              text={trans("pages.register.already_have_account?")}
            />
            <InterTag
              color={COLORS.primary}
              text={trans("general.signin")}
              hoverUnderline
              onClick={() => {
                navigate("/login");
              }}
            />
          </Flex>
        )}
      </Flex>
    );
  };
  const mobileStepRenderer = (children: JSX.Element, gotoSignin?: boolean) => {
    return (
      <Flex column style={{ marginTop: "80px", padding: "0 20px" }} gap={40}>
        {children}
        {gotoSignin && (
          <Flex row gap={4} center>
            <InterTag
              color={COLORS.secondary}
              text={trans("pages.register.already_have_account?")}
            />
            <InterTag
              color={COLORS.primary}
              text={trans("general.signin")}
              hoverUnderline
              onClick={() => {
                navigate("/login");
              }}
            />
          </Flex>
        )}
      </Flex>
    );
  };

  const renderer = (
    isLaptop: boolean,
    stepRenderer: (children: JSX.Element, gotoSignin?: boolean) => JSX.Element
  ) => {
    if (step === 0)
      return stepRenderer(
        <Step1
          onConfirm={(obj?) => {
            setState2((prev) => ({
              ...prev,
              step: 1,
              data: { ...prev.data, ...obj },
            }));
          }}
          errors={pick(state2.errors, ["email", "termsAccepted", "azure"])}
          state={pick(state2.data, ["email", "termsAccepted", "azure"])}
          onChange={handleChange}
          setErrors={handleErrors}
          {...{ isLaptop }}
        />,
        true
      );

    if (step === 1) {
      return stepRenderer(
        <Step2
          state={pick(state2.data, [
            "name",
            "password",
            "phoneNumber",
            "country",
            "tourismRegisterType",
            "tourismRegisterNumber",
            "azure",
          ])}
          onChange={handleChange}
          onConfirm={(obj?) => {
            setState2((prev) => ({
              ...prev,
              step: 2,
              data: { ...prev.data, ...obj },
            }));
          }}
          setErrors={handleErrors}
          errors={pick(state2.errors, [
            "name",
            "password",
            "phoneNumber",
            "country",
            "tourismRegisterType",
            "tourismRegisterNumber",
            "azure",
          ])}
          {...{ isLaptop }}
        />
      );
    }

    if (step === 2) {
      const handleRegister = (onError: (err: any) => void) => {
        const data = {
          ...state2.data,
        };
        axios
          .post(constructApiAddress("/v2/managers/register", true), data)
          .then((res) => {
            const {
              data: { token, account, hotel },
            } = res;
            setProfile(account);
            sessionStorage.setItem("plan-token", token);
            sessionStorage.setItem("plan-hotel", JSON.stringify(hotel));
            setTimeout(() => {
              navigate("/plan");
            }, 100);
          })
          .catch(onError);
      };
      return stepRenderer(
        <Step3
          state={pick(state2.data, [
            "accommodationName",
            "accommodationType",
            "accommodationNumberOfUnits",
            "accommodationWebsite",
            "accommodationAddress",
            "accommodationEmail",
            "accommodationPhoneNumber",
            "accommodationPostalCode",
            "accommodationDistrict",
            "accommodationCounty",
          ])}
          onChange={handleChange}
          setErrors={handleErrors}
          onConfirm={handleRegister}
          errors={pick(state2.errors, [
            "accommodationName",
            "accommodationType",
            "accommodationNumberOfUnits",
            "accommodationWebsite",
            "accommodationAddress",
            "accommodationEmail",
            "accommodationPhoneNumber",
            "accommodationPostalCode",
            "accommodationDistrict",
            "accommodationCounty",
          ])}
          {...{ isLaptop }}
        />
      );
    }

    return null;
  };

  if (isLaptop)
    return (
      <NoAuthDesktopWrapper>
        {renderer(true, desktopStepRenderer)}
      </NoAuthDesktopWrapper>
    );

  return (
    <NoAuthMobileWrapper
      onGoBack={step === 0 ? undefined : () => setStep(step - 1)}
    >
      {renderer(false, mobileStepRenderer)}
    </NoAuthMobileWrapper>
  );
};

export default Register;
