import { PaymentMethod } from "@stripe/stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";
import useLocalizationState from "../context/Localization/hooks/useLocalizationState";
import { tHotelId } from "../models/hotel";
import { constructApiAddress } from "../utils/apiCall";
import { getErrorMessage } from "../utils/httpResponses/others";
import { STRIPE_ENV } from "../utils/stripe";
import useGetRequest from "./apiRequests/useGetRequest";

export type tPaymentMethod = PaymentMethod;

const useListPaymentMethods = (
  hotelId: tHotelId,
  { doRequest = true }: { doRequest: boolean } = { doRequest: true }
) => {
  const { trans } = useLocalizationState();
  const getRequest = useGetRequest<{ paymentMethods: tPaymentMethod[] }>({
    paymentMethods: [],
  });
  const [redoRequest, setRedoRequest] = useState(false);

  useEffect(() => {
    if (hotelId && doRequest) {
      getRequest.pending();
      axios
        .get(
          constructApiAddress(
            `/v2/hotels/${hotelId}/stripe/payment-methods`,
            false
          ),
          { params: { stripeEnv: STRIPE_ENV } }
        )
        .then((res) => {
          const {
            data: { paymentMethods },
          } = res;
          getRequest.resolve({ paymentMethods });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getRequest.reject(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId, trans, redoRequest, doRequest]);

  const run = () => {
    setRedoRequest((prev) => !prev);
  };

  return { request: getRequest, run };
};

export default useListPaymentMethods;
