import { has, round, sum } from "lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "rsuite";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import BasicCard from "../../../../components/Cards/BasicCard";
import { iIconBgProps } from "../../../../components/Icons/IconBg";
import { iTextProps } from "../../../../components/Text";
import { toTextProps } from "../../../../components/Text/utils";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../../../hooks/apiRequests/useGetMeasures";
import nUseGetMeasures from "../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { TE, tMeasureTE, tMeasureTW, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_MASS_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { getMeasureInfo } from "../../../../utils/measures";
import { thousandsSeparation } from "../../../../utils/numbers";
import { renderRequestedData } from "../../../../utils/render";

type tCardMeasures = {
  to: string;
  label: string | iTextProps;
  tooltip?: string;
  icon: iIconBgProps;
  key: "te" | "tw" | "co2";
  valueFromMeasuresRequest(
    data: nUseGetMeasures.tFunctionReturn<[tMeasureTE | tMeasureTW]>["data"]
  ): number;
  unit: string;
  converter: nUnitConverter.tFunction;
};

interface iProps {
  period: [Date, Date];
  colBreakpoints: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
}

const ConsumptionCards: React.FC<iProps> = ({ period, colBreakpoints }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotel } = useHotelState();
  const periodParams = period
    ? {
        from: moment(period[0]).toISOString(),
        to: moment(period[1]).toISOString(),
      }
    : {};
  const homologuePeriodParams = period
    ? {
        from: moment(period[0]).subtract(1, "year").toISOString(),
        to: moment(period[1]).subtract(1, "year").toISOString(),
      }
    : {};

  const measuresRequest = useGetMeasures<[tMeasureTE | tMeasureTW]>(
    hotel,
    {
      measures: [TE, TW],
      ...periodParams,
    },
    { useLocalApi: true }
  );
  const homologueMeasuresRequest = useGetMeasures<[tMeasureTE | tMeasureTW]>(hotel, {
    measures: [TE, TW],
    ...homologuePeriodParams,
  });

  const { getMeasuresTracked } = useHotelMeasuresTrackedState();

  const measuresList: tCardMeasures[] = [];

  const trackedMeasures = getMeasuresTracked();

  if (trackedMeasures.te || trackedMeasures.tw) {
    measuresList.push({
      to: "/consumption",
      label: { text: trans("general.co2_emissions"), asHTML: true },
      icon: { Element: EcoIcon, fill: COLORS.esg_environment },
      key: "co2",
      valueFromMeasuresRequest(data) {
        return has(data, "co2") ? sum(data.co2) : 0;
      },
      unit: DEFAULT_MASS_MEASURE_UNIT,
      converter: convertMassUnit,
    });

    if (trackedMeasures.te) {
      measuresList.push({
        to: "/consumption/electricity",
        label: trans("general.measures_.electricity"),
        icon: {
          Element: ElectricBoltIcon,
          fill: getMeasureInfo(TE).color,
          backgroundTrasparency: 20,
        },
        key: "te",
        valueFromMeasuresRequest(data) {
          return has(data, ["grouped", "te", "measurements"])
            ? sum(data.grouped.te.measurements)
            : 0;
        },
        unit: DEFAULT_ENERGY_MEASURE_UNIT,
        converter: convertEnergyUnit,
      });
    }

    if (trackedMeasures.tw) {
      measuresList.push({
        to: "/consumption/water",
        label: trans("general.measures_.water"),
        icon: { Element: WaterDropIcon, fill: getMeasureInfo(TW).color },
        key: "tw",
        valueFromMeasuresRequest(data) {
          return has(data, ["grouped", "tw", "measurements"])
            ? sum(data.grouped.tw.measurements)
            : 0;
        },
        unit: DEFAULT_WATER_MEASURE_UNIT,
        converter: convertVolumeUnit,
      });
    }
  }

  return renderRequestedData(measuresRequest.status, {
    pending: () => (
      <>
        {measuresList.map(({ to, label, icon, key }) => (
          <Col key={key} {...colBreakpoints}>
            <BasicCard
              onClick={() => {
                navigate(to);
              }}
              hover
              icon={icon}
              value={""}
              valueLoading
              comparisonLoading
              label={{ ...toTextProps(label), asHTML: true }}
            />
          </Col>
        ))}
      </>
    ),
    resolved: () => (
      <>
        {measuresList.map(({ to, label, icon, key, unit, valueFromMeasuresRequest, converter }) => {
          const value = valueFromMeasuresRequest(measuresRequest.data);
          const converted = converter(value, { from: unit });
          return (
            <Col key={key} {...colBreakpoints}>
              <BasicCard
                onClick={() => {
                  navigate(to);
                }}
                hover
                icon={icon}
                value={{
                  text: `${thousandsSeparation(round(converted.value, 2))} ${converted.unitInHTML}`,
                  asHTML: true,
                }}
                label={{ ...toTextProps(label) }}
                comparisonLoading={homologueMeasuresRequest.isLoading}
                comparison={{
                  baseValue: valueFromMeasuresRequest(homologueMeasuresRequest.data),
                  value: value,
                }}
              />
            </Col>
          );
        })}
      </>
    ),
    rejected: () => (
      <>
        {measuresList.map(({ to, label, icon, key }) => {
          return (
            <Col key={key} {...colBreakpoints}>
              <BasicCard
                onClick={() => {
                  navigate(to);
                }}
                hover
                icon={icon}
                label={{ ...toTextProps(label), asHTML: true }}
                error={measuresRequest.error || ""}
                reload
                onReload={(e) => {
                  e.stopPropagation();
                  measuresRequest.redoRequest();
                  homologueMeasuresRequest.redoRequest();
                }}
              />
            </Col>
          );
        })}
      </>
    ),
  });
};

export default ConsumptionCards;
