import axios from "axios";
import { min, round, sortBy } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "rsuite";
import { ReactComponent as DomainIcon } from "../../../assets/icons/domain.svg";
import { ReactComponent as EcoIcon } from "../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../assets/icons/water_drop.svg";
import Flex from "../../../components/Flex";
import PageSection from "../../../components/PageSection";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleTableWrapper from "../../../components/RsuiteWrapper/SimpleTable";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleButtonCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleButtonCell";
import SimpleErrorCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleErrorCell";
import SimpleKeyValueCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleLoadingCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleLoadingCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useHotelsListState from "../../../context/Hotel/hooks/hotelState/useHotelsListState";
import { nHotel } from "../../../context/Hotel/interfaces";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../context/Profile/hooks/useProfileDispatch";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import useGetDefaultConverionFactors from "../../../hooks/useGetDefaultConverionFactors";
import usePeriodState from "../../../hooks/usePeriodState";
import useScreenSize from "../../../hooks/useScreenSize";
import { tHotelId } from "../../../models/hotel";
import { tMeasureTE, tMeasureTW } from "../../../models/measures";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../utils/convertUnits";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { thousandsSeparation } from "../../../utils/numbers";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";

type tHotelsConsumptionRequest = Record<
  tHotelId,
  Record<tMeasureTE | tMeasureTW, number>
>;

interface iTableWrappedProps {
  outletContainerHeight: number;
  period: [Date, Date];
}

const TableWrapped: React.FC<iTableWrappedProps> = ({
  outletContainerHeight,
  period,
}) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotels } = useHotelsListState();
  const getRequest = useGetRequest<tHotelsConsumptionRequest>({});
  const { setActiveHotel } = useProfileDispatch();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT");

  useEffectSafe(() => {
    getRequest.pending();
    axios
      .get(constructApiAddress("/v2/managers/hotels/consumption", true), {
        params: {
          from: period[0],
          to: period[1],
        },
      })
      .then((res) => {
        const {
          data: { hotels },
        } = res;
        getRequest.resolve(hotels);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        getRequest.reject(error, true);
      });
  }, [trans, period]);

  const options = (
    dataKey: string,
    item: nHotel.tHotelAugmented
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "select",
      label: trans("general.select"),
      onClick: () => {
        setActiveHotel(item._id);
        setTimeout(() => {
          navigate("/overview");
        }, 200);
      },
    });

    return options;
  };

  const tableHeight = min([
    (hotels.length as number) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT,
    outletContainerHeight - 270,
  ]);

  const alphebeticalHotels = sortBy(hotels, "name");

  return (
    <SimpleTableWrapper
      height={tableHeight}
      headerHeight={TABLE_HEADER_HEIGHT}
      rowHeight={TABLE_ROW_HEIGHT.M}
      data={alphebeticalHotels}
    >
      <Table.Column fullText flexGrow={3}>
        <SimpleHeaderCell name={trans("general.property")} />
        <SimpleKeyValueCell dataKey="name" />
      </Table.Column>
      <Table.Column flexGrow={3}>
        <SimpleHeaderCell name={trans("general.location")} />
        <SimpleKeyValueCell
          dataKey="location"
          descriptionFunction={(item: nHotel.tHotelAugmented) =>
            item.location.county
          }
          textFunction={(item: nHotel.tHotelAugmented) =>
            item.location.district
          }
        />
      </Table.Column>
      <Table.Column flexGrow={2}>
        <SimpleHeaderCell
          name={{ text: trans("general.co2_emissions"), asHTML: true }}
          icon={{ Element: EcoIcon, fill: COLORS.esg_environment }}
        />
        {getRequest.isLoading || conversionFactorsRequest.isLoading ? (
          <SimpleLoadingCell dataKey="footprint" />
        ) : getRequest.isResolved && conversionFactorsRequest.isResolved ? (
          <SimpleKeyValueCell
            dataKey="footprint"
            textFunction={(item: nHotel.tHotelAugmented) => {
              if (!item.ipValidated) return trans("IP restricted");
              const electricity =
                getRequest.data[item._id].te *
                conversionFactorsRequest.data.electricity_kWh_to_kgCO2e;
              const water =
                getRequest.data[item._id].tw *
                conversionFactorsRequest.data.water_m3_to_kgCO2e;

              const total = electricity + water;

              const converted = convertMassUnit(total, { forceUnit: null });

              return `${thousandsSeparation(round(converted.value, 2))} ${
                converted.unit
              }`;
            }}
            textProps={(item: nHotel.tHotelAugmented) => ({
              asHTML: true,
              bold: false,
              ...(item.ipValidated ? {} : { color: COLORS.error }),
            })}
          />
        ) : getRequest.isRejected ? (
          <SimpleErrorCell
            dataKey="footprint"
            message={trans("Error loading hotel data")}
          />
        ) : (
          <SimpleErrorCell
            dataKey="footprint"
            message={trans("Error loading footprint conversion multipliers")}
          />
        )}
      </Table.Column>
      <Table.Column flexGrow={2}>
        <SimpleHeaderCell
          name={trans("general.measures_.electricity")}
          icon={{ Element: ElectricBoltIcon, fill: COLORS.energy }}
        />
        {getRequest.isLoading ? (
          <SimpleLoadingCell dataKey="electricity" />
        ) : getRequest.isResolved ? (
          <SimpleKeyValueCell
            dataKey="electricity"
            textFunction={(item: nHotel.tHotelAugmented) => {
              if (!item.ipValidated) return trans("IP restricted");

              const converted = convertEnergyUnit(getRequest.data[item._id].te);
              return `${thousandsSeparation(round(converted.value, 2))} ${
                converted.unit
              }`;
            }}
            textProps={(item: nHotel.tHotelAugmented) => ({
              asHTML: true,
              bold: false,
              ...(item.ipValidated ? {} : { color: COLORS.error }),
            })}
          />
        ) : (
          <SimpleErrorCell
            dataKey="electricity"
            message={trans("Error loading hotel data")}
          />
        )}
      </Table.Column>
      <Table.Column flexGrow={2}>
        <SimpleHeaderCell
          name={trans("general.measures_.water")}
          icon={{ Element: WaterDropIcon, fill: COLORS.water }}
        />
        {getRequest.isLoading ? (
          <SimpleLoadingCell dataKey="water" />
        ) : getRequest.isResolved ? (
          <SimpleKeyValueCell
            dataKey="water"
            textFunction={(item: nHotel.tHotelAugmented) => {
              if (!item.ipValidated) return trans("IP restricted");
              const converted = convertVolumeUnit(getRequest.data[item._id].tw);
              return `${thousandsSeparation(round(converted.value, 2))} ${
                converted.unit
              }`;
            }}
            textProps={(item: nHotel.tHotelAugmented) => ({
              asHTML: true,
              bold: false,
              ...(item.ipValidated ? {} : { color: COLORS.error }),
            })}
          />
        ) : (
          <SimpleErrorCell
            dataKey="water"
            message={trans("Error loading hotel data")}
          />
        )}
      </Table.Column>
      <Table.Column>
        <SimpleHeaderCell />
        <SimpleButtonCell
          onClick={(item: nHotel.tHotelAugmented) => {
            setActiveHotel(item._id);
            setTimeout(() => {
              navigate("/overview");
            }, 200);
          }}
          text={trans("general.select")}
          dataKey="button"
        />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell />
        <SimpleActionCell options={options} />
      </Table.Column>
    </SimpleTableWrapper>
  );
};

const TableOfPropertiesWrapped2: React.FC<{
  outletContainerHeight: number;
}> = ({ outletContainerHeight }) => {
  const [period, setPeriod] = usePeriodState();
  return (
    <Flex column gap={8}>
      <Flex row>
        <SimpleDateRangePicker
          value={period}
          onChange={setPeriod}
          style={{ paddingLeft: "8px" }}
        />
      </Flex>
      <TableWrapped {...{ outletContainerHeight, period }} />
    </Flex>
  );
};

const TableOfPropertiesWrapped1: React.FC = () => {
  const { outlet: o } = useScreenSize();

  if (!o) return null;

  return <TableOfPropertiesWrapped2 outletContainerHeight={o.height} />;
};

interface iProps {}

const TableOfProperties: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <PageSection
      title={trans("Properties Overview")}
      icon={DomainIcon}
      description={trans("pages.properties.sections.overview.description")}
    >
      <TableOfPropertiesWrapped1 />
    </PageSection>
  );
};

export default TableOfProperties;
