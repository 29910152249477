import axios from "axios";
import { floor, min } from "lodash";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "rsuite";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as ContentCopyIcon } from "../../../assets/icons/content_copy.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as SquareFootIcon } from "../../../assets/icons/square_foot.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as WorkSpacesIcon } from "../../../assets/icons/workspaces.svg";
import Flex from "../../../components/Flex";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tHotelSpace, tHotelSpaceId } from "../../../models/hotel";
import {
  apiAddress,
  constructApiAddress,
  LOCAL_NODE_SERVER_V2,
} from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { thousandsSeparation } from "../../../utils/numbers";
import { copyToClipboard } from "../../../utils/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import NoSpaces from "./noSpaces";
import SpaceModal from "./spaceModal";

interface iProps {
  outletContainerWidth: number;
}

const SpacesTable: React.FC<iProps> = ({ outletContainerWidth }) => {
  const toaster = useSimpleToaster();
  const navigate = useNavigate();
  const deleteRequest = useDeleteRequest();
  const [spaceModal, setSpaceModal] = useState<
    | {
        open: false;
        spaceId: null;
        spaceName: null;
      }
    | {
        open: true;
        spaceId: tHotelSpaceId;
        spaceName: string;
      }
  >({ open: false, spaceId: null, spaceName: null });
  const { trans } = useLocalizationState();
  const {
    hotelId,
    hotel: { spaces },
    findSpaceSpaceAggregates,
  } = useHotelSpacesState();
  const { updateHotel, updatingHotel, resolveHotel } = useHotelDispatch();
  const profilePermissions = useProfilePermissions();

  const options = (
    dataKey: string,
    space: tHotelSpace
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "configure",
      icon: { Element: SettingsIcon, fill: COLORS.secondary },
      label: {
        text: trans("general.configure"),
      },
      onClick() {
        setSpaceModal({
          open: true,
          spaceId: space._id,
          spaceName: space.name,
        });
      },
      show: profilePermissions.writeSpaces,
    });

    options.push({
      key: "view space",
      icon: { Element: VisibilityIcon, fill: COLORS.secondary },
      label: {
        text: trans("general.view_x", { parameters: [trans("general.space")] }),
      },
      onClick() {
        navigate(`/spaces/${space._id}`);
      },
      show: profilePermissions.readSpaces,
    });

    options.push({
      key: "copy id to clipboard",
      label: trans(
        "pages.spaces.tabs.spaces.management.table.options.copy_space_id"
      ),
      icon: ContentCopyIcon,
      onClick() {
        copyToClipboard(space._id);
      },
      show: apiAddress() === LOCAL_NODE_SERVER_V2,
    });

    options.push({
      key: "delete",
      icon: { Element: DeleteIcon, fill: COLORS.error },
      label: {
        text: trans("general.remove"),
        color: COLORS.error,
      },
      onClick() {
        updatingHotel();
        deleteRequest.pending();
        axios
          .delete(
            constructApiAddress(
              `/v2/hotels/${hotelId}/spaces/${space._id}`,
              false
            )
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(hotelId, hotel);
            toaster.success(
              trans("general.removed_x", {
                parameters: [trans("general.space")],
              })
            );
            deleteRequest.resolve();
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            resolveHotel();
            deleteRequest.reject(error, true);
          });
      },
      show: profilePermissions.writeSpaces,
    });

    return options;
  };

  const tableHeight =
    (min([5, spaces.length]) as number) * TABLE_ROW_HEIGHT.M +
    TABLE_HEADER_HEIGHT;

  const spaceWidth = floor(0.15 * outletContainerWidth);
  const groupWidth = floor(0.2 * outletContainerWidth);
  const typeWidth = floor(0.2 * outletContainerWidth);
  const zoneWidth = floor(0.2 * outletContainerWidth);
  const areaWidth = floor(0.1 * outletContainerWidth);

  return (
    <Fragment>
      {spaceModal.open && (
        <SpaceModal
          onClose={() => {
            setSpaceModal({ open: false, spaceId: null, spaceName: null });
          }}
          {...{ ...spaceModal }}
        />
      )}
      <div className="table-wrapper">
        <Table
          id={"TABLE.SPACES"}
          height={tableHeight}
          data={spaces}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          loading={deleteRequest.isLoading}
          renderEmpty={(info: any) => {
            return null;
          }}
        >
          <Table.Column width={spaceWidth}>
            <SimpleHeaderCell
              icon={MeetingRoomIcon}
              name={trans("general.space")}
            />
            <SimpleKeyValueCell
              textProps={(space) => ({
                hoverUnderline: true,
                onClick: () => navigate(`/spaces/${space._id}`),
              })}
              dataKey="name"
            />
          </Table.Column>
          <Table.Column width={groupWidth}>
            <SimpleHeaderCell
              icon={WorkSpacesIcon}
              name={trans("general.space_categories_.short.group")}
            />

            <BaseCell dataKey="group">
              {(rowData: tHotelSpace) => {
                const groups = findSpaceSpaceAggregates(rowData._id, "group");

                return (
                  <Flex row>
                    {groups
                      .flatMap((group) => [
                        <InterTag
                          key={group._id}
                          asSpan
                          hoverUnderline
                          size={12}
                          text={group.name}
                          onClick={() =>
                            navigate(`/space-aggregates/${group._id}`)
                          }
                        />,
                        <InterTag key={`${group._id},`} size={12} text=",  " />,
                      ])
                      .slice(0, groups.length * 2 - 1)}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={typeWidth}>
            <SimpleHeaderCell
              icon={CategoryIcon}
              name={trans("general.space_categories_.short.type")}
            />

            <BaseCell dataKey="group">
              {(rowData: tHotelSpace) => {
                const types = findSpaceSpaceAggregates(rowData._id, "type");

                return (
                  <Flex row>
                    {types
                      .flatMap((group) => [
                        <InterTag
                          key={group._id}
                          asSpan
                          hoverUnderline
                          size={12}
                          text={group.name}
                          onClick={() =>
                            navigate(`/space-aggregates/${group._id}`)
                          }
                        />,
                        <InterTag key={`${group._id},`} size={12} text=",  " />,
                      ])
                      .slice(0, types.length * 2 - 1)}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={zoneWidth}>
            <SimpleHeaderCell
              icon={StacksIcon}
              name={trans("general.space_categories_.short.zone")}
            />

            <BaseCell dataKey="zone">
              {(rowData: tHotelSpace) => {
                const zones = findSpaceSpaceAggregates(rowData._id, "zone");

                return (
                  <Flex row>
                    {zones
                      .flatMap((zone) => [
                        <InterTag
                          key={zone._id}
                          asSpan
                          hoverUnderline
                          size={12}
                          text={zone.name}
                          onClick={() =>
                            navigate(`/space-aggregates/${zone._id}`)
                          }
                        />,
                        <InterTag key={`${zone._id},`} size={12} text=",  " />,
                      ])
                      .slice(0, zones.length * 2 - 1)}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={areaWidth}>
            <SimpleHeaderCell
              icon={SquareFootIcon}
              name={trans("general.area")}
            />
            <SimpleKeyValueCell
              textProps={() => ({ asHTML: true })}
              dataKey="areaInSquareMeters"
              textFunction={(rowData) =>
                rowData["areaInSquareMeters"]
                  ? `${thousandsSeparation(
                      rowData["areaInSquareMeters"].toFixed(2)
                    )} m<sup>2</sup>`
                  : null
              }
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
        {spaces.length === 0 && <NoSpaces category="space" />}
      </div>
    </Fragment>
  );
};

export default SpacesTable;
