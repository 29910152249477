import React from "react";
import { iTextProps } from "../../../../Text";
import SimpleButton from "../../../SimpleButton";
import BaseCell from "../BaseCell";
import { toTextProps } from "../../../../Text/utils";
import { COLORS } from "../../../../../utils/colors";

interface iProps {
  rowData?: Record<string, any>;
  dataKey: string;
  rowIndex?: number;

  onClick(rowData: any, dataKey: string, rowIndex: number): void;
  text: string | iTextProps;

  [key: string]: any;
}

const SimpleButtonCell: React.FC<iProps> = ({ onClick, text, ...props }) => {
  const { rowData, dataKey, rowIndex } = props;
  return (
    <BaseCell {...{ ...props }}>
      <SimpleButton
        appearance="ghost"
        size="xs"
        {...{
          onClick: () => onClick(rowData, dataKey, rowIndex!),
          text: { color: COLORS.primary, ...toTextProps(text) },
        }}
      />
    </BaseCell>
  );
};

export default SimpleButtonCell;
