import axios from "axios";
import moment from "moment";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { ReactComponent as DateRangeIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tStay } from "../../../models/reservation";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import AverageConsumption from "./averageConsumption";
import Awards from "./awards";
import GuestInformation from "./guestInformation";
import { has } from "lodash";

interface iProps {
  stay: tStay;
}

const ReservationWrapped2: React.FC<iProps> = ({ stay }) => {
  const { trans } = useLocalizationState();
  const { findSpace } = useHotelSpacesState();

  const numberOfNights = moment(stay.endAt)
    .endOf("day")
    .diff(moment(stay.startAt).startOf("day"), "days");

  const space = findSpace(stay.room);

  return (
    <>
      <Flex column gap={30}>
        <Flex row gap={16}>
          <Link to={`/spaces/${stay.room}`}>
            <Flex row gap={8} className="card-s hover-box-shadow">
              <Icon
                Element={MeetingRoomIcon}
                fill={COLORS.secondary}
                size={20}
              />
              <InterTag
                color={COLORS.secondary}
                text={
                  space
                    ? space.name
                    : trans("Space $0", { parameters: [stay.room] })
                }
              />
            </Flex>
          </Link>
          <Flex row gap={8} className="card-s">
            <Icon Element={DateRangeIcon} fill={COLORS.secondary} size={20} />
            <InterTag
              color={COLORS.secondary}
              text={`${moment(stay.startAt)
                .format("MMM DD, YYYY (HH:mm)")
                .toLowerCase()} - ${moment(stay.endAt)
                .format("MMM DD, YYYY (HH:mm)")
                .toLowerCase()}`}
            />
          </Flex>
          <Flex row gap={8} className="card-s">
            <Icon Element={DateRangeIcon} fill={COLORS.secondary} size={20} />
            <InterTag
              color={COLORS.secondary}
              text={`${numberOfNights} ${trans("nights")}`}
            />
          </Flex>
        </Flex>
        <GuestInformation
          {...{
            reservationId: stay._id,
            mainGuest: stay.mainGuest,
            additionalGuests: stay.additionalGuests,
          }}
        />
        <AverageConsumption reservation={stay} />
        <Awards reservation={stay} />
      </Flex>
    </>
  );
};

const ReservationWrapped1: React.FC = () => {
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();
  const params = useParams<{ idCode: string }>();
  const location = useLocation();

  const request = useGetRequest<{ stay: null | tStay }>({ stay: null });

  useEffectSafe(() => {
    request.pending();
    axios
      .get(
        constructApiAddress(
          `/v2/hotels/${hotelId}/stays/${params.idCode}`,
          false
        )
      )
      .then((res) => {
        request.resolve({ stay: res.data.stay });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error, true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId, params.idCode, trans]);

  return (
    <>
      <TopBar
        page={
          has(location.state, "reservation.code")
            ? location.state.reservation.code
            : request.data.stay?.code || params.idCode || ""
        }
        parentPages={[{ transKey: "general.guests", to: "/guests" }]}
      />
      {request.isLoading ? (
        <Flex center middle>
          <Loader size="lg" />
        </Flex>
      ) : request.isResolved && request.data.stay ? (
        <ReservationWrapped2 stay={request.data.stay} />
      ) : null}
    </>
  );
};

const Reservation: React.FC = () => {
  return (
    <div>
      <ReservationWrapped1 />
      <PageBottomPadding />
    </div>
  );
};

export default Reservation;
