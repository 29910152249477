import React, { Fragment } from "react";
import { Input, InputGroup, InputNumber } from "rsuite";
import { ReactComponent as CallIcon } from "../../../assets/icons/call.svg";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as LanguageIcon } from "../../../assets/icons/language.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location_on.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tIcon } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { preventDefault } from "../../../utils/others";
import { tRegisterState2 } from "./register";

type tDataKeys = keyof Pick<
  tRegisterState2["data"],
  | "accommodationName"
  | "accommodationType"
  | "accommodationNumberOfUnits"
  | "accommodationWebsite"
  | "accommodationAddress"
  | "accommodationEmail"
  | "accommodationPhoneNumber"
  | "accommodationPostalCode"
  | "accommodationDistrict"
  | "accommodationCounty"
>;

interface iProps {
  state: Pick<tRegisterState2["data"], tDataKeys>;
  errors: Pick<tRegisterState2["errors"], tDataKeys>;
  onChange(key: tDataKeys, value: any): any;
  setErrors(errors: Partial<Pick<tRegisterState2["errors"], tDataKeys>>): any;
  onConfirm(onError: (err: any) => void): any;
  isLaptop: boolean;
}

const Step3: React.FC<iProps> = ({
  errors,
  onChange,
  onConfirm,
  setErrors,
  state,
  isLaptop,
}) => {
  const { trans } = useLocalizationState();

  const postRequest = usePostRequest();

  const handleContinue = () => {
    postRequest.pending();
    onConfirm((err) => {
      const error = getErrorMessage(err, trans);
      postRequest.reject(error, true);
    });
  };

  const handleChange = (key: tDataKeys) => (value: any) => {
    onChange(key, value);
  };

  const inputs = {
    accommodationName: {
      key: "accommodationName",
      name: "pages.register.inputs.accommodation_name.label",
      placeholder: "pages.register.inputs.accommodation_name.placeholder",
      icon: { Element: HotelIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationType: {
      key: "accommodationType",
      name: "pages.register.inputs.accommodation_type.label",
      placeholder: "pages.register.inputs.accommodation_type.placeholder",
      icon: { Element: HotelIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationEmail: {
      key: "accommodationEmail",
      name: "pages.register.inputs.accommodation_email.label",
      placeholder: "general.placeholders.email",
      icon: { Element: MailIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationPhoneNumber: {
      key: "accommodationPhoneNumber",
      name: "pages.register.inputs.accommodation_phone_number.label",
      placeholder: "general.placeholders.phone_number",
      icon: { Element: CallIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationWebsite: {
      key: "accommodationWebsite",
      name: "pages.register.inputs.accommodation_website.label",
      placeholder: "pages.register.inputs.accommodation_website.placeholder",
      icon: { Element: LanguageIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationAddress: {
      key: "accommodationAddress",
      name: "pages.register.inputs.accommodation_address.label",
      placeholder: "pages.register.inputs.accommodation_address.placeholder",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationCounty: {
      key: "accommodationCounty",
      name: "pages.register.inputs.accommodation_city.label",
      placeholder: "pages.register.inputs.accommodation_city.placeholder",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationDistrict: {
      key: "accommodationDistrict",
      name: "pages.register.inputs.accommodation_district.label",
      placeholder: "pages.register.inputs.accommodation_district.placeholder",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
    accommodationPostalCode: {
      key: "accommodationPostalCode",
      name: "pages.register.inputs.accommodation_postal_code.label",
      placeholder:
        "pages.register.inputs.accommodation_postal_code.placeholder",
      icon: { Element: LocationIcon, fill: COLORS.secondary, size: 24 },
    },
  } as Record<
    tDataKeys,
    { key: tDataKeys; name: string; placeholder: string; icon: tIcon }
  >;

  const renderTextInputs = (keys: tDataKeys[]) => {
    return keys.map((key) => {
      const obj = inputs[key as tDataKeys];
      const { name, placeholder, icon } = obj;

      return (
        <InputWrapper key={key} label={trans(name)}>
          <InputGroup disabled={postRequest.isLoading}>
            <InputGroup.Addon>
              <Icon {...{ ...icon }} />
            </InputGroup.Addon>
            <Input
              value={state[key]}
              onChange={handleChange(key)}
              type="text"
              placeholder={trans(placeholder)}
              size="lg"
            />
          </InputGroup>
        </InputWrapper>
      );
    });
  };

  const renderNumberInput = () => {
    return (
      <InputWrapper
        label={trans("pages.register.inputs.accommodation_units.label")}
      >
        <InputGroup disabled={postRequest.isLoading}>
          <InputGroup.Addon>
            <Icon Element={HotelIcon} fill={COLORS.secondary} size={24} />
          </InputGroup.Addon>
          <InputNumber
            value={state["accommodationNumberOfUnits"]}
            onChange={handleChange("accommodationNumberOfUnits")}
            type="text"
            placeholder={trans(
              "pages.register.inputs.accommodation_units.placeholder"
            )}
            size="lg"
            min={1}
          />
        </InputGroup>
      </InputWrapper>
    );
  };

  if (postRequest.isLoading) {
    return (
      <Fragment>
        <Flex column center middle gap={8}>
          <InterTag
            size={20}
            color={COLORS.secondary}
            text={trans("pages.register.steps.2.loading.title")}
            bold
          />
          <InterTag
            style={{ textAlign: "center" }}
            wrap
            size={16}
            color={COLORS.secondary}
            text={trans("pages.register.steps.2.loading.description")}
          />
        </Flex>
        <Flex center>
          <img
            src="https://noytrall-property-app-assets.s3.eu-west-1.amazonaws.com/Scaffold-amico+1.svg"
            alt="building account"
          />
        </Flex>
      </Fragment>
    );
  }

  const renderGreetings = () => (
    <Flex column middle gap={8}>
      <InterTag
        size={20}
        color={COLORS.secondary}
        text={trans("pages.register.steps.2.title")}
        bold
      />
      <InterTag
        size={16}
        color={COLORS.secondary}
        text={trans("pages.register.steps.2.description")}
      />
    </Flex>
  );

  const renderForm = () => (
    <form onSubmit={preventDefault(handleContinue)}>
      <Flex column gap={16}>
        {renderTextInputs(["accommodationName", "accommodationType"])}
        {renderNumberInput()}
        {renderTextInputs([
          "accommodationWebsite",
          "accommodationEmail",
          "accommodationPhoneNumber",
          "accommodationAddress",
          "accommodationCounty",
          "accommodationDistrict",
          "accommodationPostalCode",
        ])}
      </Flex>
      <input type="submit" hidden />
    </form>
  );

  const renderButton = () => (
    <SimpleButton
      text={{
        text: trans("general.continue"),
        size: 20,
        color: COLORS.white,
        bold: true,
      }}
      onClick={handleContinue}
      loading={postRequest.isLoading}
      appearance="primary"
    />
  );

  if (isLaptop)
    return (
      <Fragment>
        {renderGreetings()}
        {renderForm()}
        {renderButton()}
      </Fragment>
    );

  return (
    <Flex column gap={30}>
      {renderGreetings()}
      <Flex column gap={30} style={{ padding: "0 8px" }}>
        {renderForm()}
        {renderButton()}
      </Flex>
      <div style={{ height: "20px" }} />
    </Flex>
  );
};

export default Step3;
