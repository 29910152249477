import axios from "axios";
import { min } from "lodash";
import React, { useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as ApprovalDelegationsIcon } from "../../../assets/icons/approval_delegation.svg";
import { ReactComponent as AvgPaceIcon } from "../../../assets/icons/avg_pace.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EcoIcon } from "../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../assets/icons/electric_bolt.svg";
import { ReactComponent as EnergyProgramTimeUsedIcon } from "../../../assets/icons/energy_program_time_used.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as ToggleOffIcon } from "../../../assets/icons/toggle_off.svg";
import { ReactComponent as ToggleOnIcon } from "../../../assets/icons/toggle_on.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import { ReactComponent as WaterDropIcon } from "../../../assets/icons/water_drop.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageSection from "../../../components/PageSection";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleAwardsCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleAwardsCell";
import SimpleKeyValueCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tIcon } from "../../../interfaces/icon";
import { tHotelPerformanceProgramItem } from "../../../models/hotel";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import CreateEditPerformanceProgram from "./createEditPerformanceProgram";

type tTableItem = tHotelPerformanceProgramItem;

interface iPerformanceWrappedProps {}

const PerformanceWrapped: React.FC<iPerformanceWrappedProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const putRequest = usePutRequest();
  const deleteRequest = useDeleteRequest();
  const [modalState, setModalState] = useState({ open: false, programId: "" });

  const handleProgramItemToggleStatus = (
    elem: tHotelPerformanceProgramItem,
    status: tHotelPerformanceProgramItem["status"]
  ) => {
    if (elem.status === status) return;
    putRequest.pending();
    axios
      .put(
        constructApiAddress(
          `/v2/hotels/${hotelId}/performance/${elem._id}`,
          true
        ),
        {
          status,
        }
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        toaster.success(
          trans(
            "pages.loyalty.sections.performance_program.notifications.status_updated"
          )
        );
        updateHotel(hotelId, hotel);
        putRequest.resolve();
      })
      .catch((err) => {
        const {
          data: { hotel },
        } = err.response;
        const error = getErrorMessage(err, trans);

        if (hotel) {
          updateHotel(hotelId, hotel);
        } else {
          toaster.error(error);
        }
        putRequest.reject(error);
      });
  };

  const options = (
    dataKey: string,
    tableItem: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "edit",
      icon: SettingsIcon,
      label: trans("general.configure"),
      onClick() {
        setModalState({ open: true, programId: tableItem._id });
      },
    });

    switch (tableItem.status) {
      case "inactive":
        options.push({
          key: "toggle status",
          label: { text: trans("general.activate"), color: COLORS.info },
          icon: { Element: ToggleOnIcon, fill: COLORS.info },
          onClick() {
            handleProgramItemToggleStatus(tableItem, "active");
          },
        });
        break;
      case "active":
        options.push({
          key: "toggle status",
          label: { text: trans("general.deactivate"), color: COLORS.info },
          icon: { Element: ToggleOffIcon, fill: COLORS.info },
          onClick() {
            handleProgramItemToggleStatus(tableItem, "inactive");
          },
        });
        break;

      default:
        break;
    }

    options.push({
      key: "delete",
      icon: { Element: DeleteIcon, fill: COLORS.error },
      label: { text: trans("general.remove"), color: COLORS.error },
      onClick() {
        deleteRequest.pending();
        axios
          .delete(
            constructApiAddress(
              `/v2/hotels/${hotelId}/performance/${tableItem._id}`,
              true
            )
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;

            updateHotel(hotelId, hotel);
            deleteRequest.resolve();
            toaster.success(trans("general.removed_successfully"));
          })
          .catch((err) => {
            const {
              data: { hotel },
            } = err.response;
            const error = getErrorMessage(err, trans);
            if (hotel) {
              updateHotel(hotelId, hotel);
            } else {
              toaster.error(error);
            }
            deleteRequest.reject(error);
          });
      },
    });

    return options;
  };

  const tableData: tTableItem[] = hotel.performanceProgram;

  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight =
    (min([4, tableData.length || 1]) as number) * rowHeight + headerHeight;

  return (
    <>
      {modalState.open && (
        <CreateEditPerformanceProgram
          programId={modalState.programId}
          open={true}
          onClose={() => setModalState({ open: false, programId: "" })}
        />
      )}
      <div className="table-wrapper">
        <Table
          id={"TABLE.PERFORMANCE"}
          height={tableHeight}
          data={tableData}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          loading={putRequest.isLoading || deleteRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.type")} />
            <BaseCell dataKey="type">
              {(item: tTableItem) => {
                let icon: tIcon;
                let label: string;
                let bgColor: string;
                switch (item.type) {
                  case "electricity":
                    icon = { Element: ElectricBoltIcon };
                    label = trans("general.measures_,electricity");
                    bgColor = COLORS.energy;
                    break;

                  case "water":
                    icon = { Element: WaterDropIcon };
                    label = trans("general.measures_.water");
                    bgColor = COLORS.water;
                    break;
                  case "footprint":
                    icon = { Element: EcoIcon };
                    label = trans("general.footprint");
                    bgColor = COLORS.emissions;
                    break;
                }
                return (
                  <Flex
                    row
                    middle
                    gap={4}
                    color={bgColor}
                    style={{
                      padding: "6px",
                      borderRadius: "4px",
                      width: "fit-content",
                    }}
                  >
                    <Icon {...{ size: 14, fill: COLORS.white, ...icon }} />
                    <InterTag text={label} size={12} color={COLORS.white} />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={VerifiedIcon}
              name={trans("general.awards")}
            />
            <SimpleAwardsCell dataKey="awards" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={AvgPaceIcon}
              name={trans("general.status")}
            />
            <SimpleKeyValueCell
              textProps={(rowData) => ({
                color:
                  COLORS[rowData.status === "active" ? "emissions" : "error"],
              })}
              textFunction={(rowData) => {
                switch (rowData.status) {
                  case "active":
                    return trans("general.active");
                  case "inactive":
                    return trans("general.inactive");
                  default:
                    break;
                }
              }}
              dataKey="status"
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={ApprovalDelegationsIcon}
              name={trans("general.awarded")}
            />
            <SimpleKeyValueCell dataKey="timesAwarded" />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

const AddButton: React.FC = () => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <CreateEditPerformanceProgram
        open={modal}
        onClose={() => setModal(false)}
      />
      <SimpleAddButton onClick={() => setModal(true)} />
    </>
  );
};

interface iProps {}

const PerformanceProgram: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <Flex row bottom between>
        <PageSection
          icon={EnergyProgramTimeUsedIcon}
          title={trans("pages.loyalty.sections.performance_program.title")}
          description={trans(
            "pages.loyalty.sections.performance_program.description"
          )}
        />
        <AddButton />
      </Flex>
      <PerformanceWrapped />
    </Flex>
  );
};

export default PerformanceProgram;
