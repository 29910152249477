import React, { Fragment, useState } from "react";
import { WhisperProps } from "rsuite";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as WorkSpacesIcon } from "../../../assets/icons/workspaces.svg";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tIconElement } from "../../../interfaces/icon";
import SpaceAggregateModal from "./spaceAggregateModal";
import SpaceModal from "./spaceModal";

interface iProps {
  placement?: WhisperProps["placement"];
}

const AddButton: React.FC<iProps> = ({ placement = "bottomEnd" }) => {
  const { trans } = useLocalizationState();
  const [spaceModal, setSpaceModal] = useState<{ open: boolean }>({
    open: false,
  });
  const [aggModal, setAggModal] = useState<{ open: false } | { open: true }>({
    open: false,
  });

  const profilePermissions = useProfilePermissions();

  const options: {
    key: string;
    label: string;
    onClick(): void;
    icon: tIconElement;
  }[] = [];

  if (profilePermissions.writeSpaces)
    options.push({
      key: "spaces",
      label: trans("general.spaces"),
      onClick: () => {
        setSpaceModal((prev) => ({ ...prev, open: true }));
      },
      icon: MeetingRoomIcon,
    });

  if (profilePermissions.writeSpaceAggregates)
    options.push({
      key: "groups",
      label: trans("general.space_categories"),
      onClick: () => {
        setAggModal({ open: true });
      },
      icon: WorkSpacesIcon,
    });

  if (options.length === 0) return null;

  return (
    <Fragment>
      <SpaceModal
        onClose={() => setSpaceModal((prev) => ({ ...prev, open: false }))}
        open={spaceModal.open}
      />
      {aggModal.open && (
        <SpaceAggregateModal
          onClose={() => setAggModal({ open: false })}
          open={true}
        />
      )}
      <SimpleWhisperPopoverDropdown options={options} {...{ placement }}>
        <SimpleAddButton />
      </SimpleWhisperPopoverDropdown>
    </Fragment>
  );
};

export default AddButton;
