import { at, isString } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../../";
import useHotelState from "./useHotelState";

const useHotelSettingsState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelSettingsState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const isSettingOn = useCallback(
    (path: string | string[]) => {
      const paths = isString(path) ? [path] : path;
      for (const path of paths) {
        if (!at(hotel.settings, path)[0]) return false;
      }
      return true;
    },
    [hotel.settings]
  );

  const areAllSettingsOn = useCallback(
    (paths: string[]) => {
      for (const path of paths) {
        if (!at(hotel.settings, path)[0]) return false;
      }
      return true;
    },
    [hotel.settings]
  );
  const isOneSettingOn = useCallback(
    (paths: string[]) => {
      for (const path of paths) {
        if (at(hotel.settings, path)[0]) return true;
      }
      return false;
    },
    [hotel.settings]
  );

  return {
    hotel,
    hotelId,
    isSettingOn,
    areAllSettingsOn,
    isOneSettingOn,
  };
};

export default useHotelSettingsState;
