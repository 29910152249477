import axios from "axios";
import { uniq } from "lodash";
import React, { Fragment, useState } from "react";
import {
  IconButton,
  List,
  Loader,
  Modal,
  ModalProps,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help.svg";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icons/Icon";
import InterTag from "../../../../components/Text/Inter";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePatchRequest, {
  tUsePatchRequest,
} from "../../../../hooks/apiRequests/usePatchRequest";
import tHotel from "../../../../models/hotel";
import { constructApiAddress } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";
import AddIpModal from "./addIpModal";

type tKey = keyof tHotel["ipRestrictions"];

interface iIpModalWrappedProps extends Pick<iProps, "onClose"> {}

const IpModalWrapped: React.FC<iIpModalWrappedProps> = ({ onClose }) => {
  const { trans } = useLocalizationState();
  const {
    hotel: { ipRestrictions },
    hotelId,
  } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [addIpModalState, setAddIpModalState] = useState<
    | {
        open: true;
        key: tKey;
      }
    | { open: false; key: null }
  >({ open: false, key: null });

  const whiteListStatusRequest = usePatchRequest();
  const whiteListIpsRequest = usePatchRequest();
  const blackListStatusRequest = usePatchRequest();
  const blackListIpsRequest = usePatchRequest();

  const handleSwitchListStatus =
    (key: tKey, request: tUsePatchRequest) => (status: boolean) => {
      request.pending();
      axios
        .patch(
          constructApiAddress(
            `/v2/hotels/${hotelId}/ip-restrictions/status`,
            false
          ),
          {
            key,
            active: status,
          }
        )
        .then((res) => {
          const {
            data: { hotel },
          } = res;
          updateHotel(hotelId, hotel);
          request.resolve();
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error, true);
        });
    };

  const handleRemoveIp = (
    key: tKey,
    ipToRemove: string,
    request: tUsePatchRequest
  ) => {
    const ips = ipRestrictions[key].ips.filter((ip) => ip !== ipToRemove);
    handleSetIpList(key, ips, request);
  };

  const handleAddIp = (key: tKey, newIp: string, request: tUsePatchRequest) => {
    const ips = uniq([...ipRestrictions[key].ips, newIp]);
    handleSetIpList(key, ips, request);
    setAddIpModalState({ open: false, key: null });
  };

  const handleSetIpList = (
    key: tKey,
    ips: string[],
    request: tUsePatchRequest
  ) => {
    request.pending();
    axios
      .patch(
        constructApiAddress(`/v2/hotels/${hotelId}/ip-restrictions/ips`, false),
        {
          key,
          ips,
        }
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);

        request.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error, true);
      });
  };

  const renderList = (
    key: tKey,
    label: string,
    tooltip: string,
    statusRequest: tUsePatchRequest,
    listRequest: tUsePatchRequest
  ) => {
    const state = ipRestrictions[key];
    return (
      <Flex one column gap={32}>
        <Flex row between middle>
          <Flex row left gap={16} middle>
            <Whisper
              trigger="hover"
              placement="right"
              speaker={<Tooltip>{tooltip}</Tooltip>}
            >
              <Flex top row gap={4}>
                <InterTag
                  size={20}
                  text={label}
                  color={COLORS.secondary}
                  bold
                />
                <Icon Element={HelpIcon} fill={COLORS.secondary} size={12} />
              </Flex>
            </Whisper>
            <Toggle
              loading={statusRequest.isLoading}
              onChange={handleSwitchListStatus(key, statusRequest)}
              size="xs"
              checked={state.active}
              checkedChildren={trans("general.enabled")}
              unCheckedChildren={trans("general.disabled")}
            />
          </Flex>

          <IconButton
            appearance="primary"
            circle
            size="xs"
            onClick={() => setAddIpModalState({ open: true, key })}
            icon={<Icon Element={AddIcon} size={24} fill={COLORS.white} />}
          />
        </Flex>

        <List hover style={{ maxHeight: "500px" }}>
          {listRequest.isLoading ? (
            <List.Item>
              <Flex middle center style={{ height: "26px" }}>
                <Loader size="xs" />
              </Flex>
            </List.Item>
          ) : state.ips.length === 0 ? (
            <List.Item>
              <Flex middle center style={{ height: "26px" }}>
                <InterTag
                  size={14}
                  color={COLORS.secondary}
                  text={trans(
                    "pages.settings.panels.security.items.ip_restrictions.modals.list.no_ips"
                  )}
                />
              </Flex>
            </List.Item>
          ) : (
            state.ips.map((ip) => (
              <List.Item key={ip}>
                <Flex row between middle style={{ padding: "0 8px" }}>
                  <InterTag text={ip} color={COLORS.secondary} size={14} />
                  <IconButton
                    onClick={() => handleRemoveIp(key, ip, listRequest)}
                    circle
                    size="xs"
                    icon={
                      <Icon
                        Element={CloseIcon}
                        fill={COLORS.secondary}
                        size={14}
                      />
                    }
                  />
                </Flex>
              </List.Item>
            ))
          )}
        </List>
      </Flex>
    );
  };

  return (
    <Fragment>
      {addIpModalState.open && (
        <AddIpModal
          title={trans(
            `pages.settings.panels.security.items.ip_restrictions.modals.add_ip.title.${
              addIpModalState.key === "blackList" ? "black" : "white"
            }`
          )}
          open={addIpModalState.open}
          onClose={() => setAddIpModalState({ open: false, key: null })}
          onConfirm={(ip: string) =>
            handleAddIp(
              addIpModalState.key,
              ip,
              addIpModalState.key === "blackList"
                ? blackListIpsRequest
                : whiteListIpsRequest
            )
          }
        />
      )}
      <Modal.Header />
      <Modal.Body>
        <Flex column gap={16}>
          <InterTag
            text={trans(
              "pages.settings.panels.security.items.ip_restrictions.modals.list.title"
            )}
            color={COLORS.secondary}
            size={40}
            bold
          />
          <Flex row gap={40}>
            {renderList(
              "whiteList",
              trans(
                "pages.settings.panels.security.items.ip_restrictions.modals.list.sections.white.title"
              ),
              trans(
                "pages.settings.panels.security.items.ip_restrictions.modals.list.sections.white.tooltip"
              ),
              whiteListStatusRequest,
              whiteListIpsRequest
            )}
            <div style={{ backgroundColor: COLORS.secondary, width: "1px" }} />
            {renderList(
              "blackList",
              trans(
                "pages.settings.panels.security.items.ip_restrictions.modals.list.sections.black.title"
              ),
              trans(
                "pages.settings.panels.security.items.ip_restrictions.modals.list.sections.black.tooltip"
              ),
              blackListStatusRequest,
              blackListIpsRequest
            )}
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
}

const IpModal: React.FC<iProps> = ({ onClose, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "lg", onClose, ...props }}>
      <IpModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default IpModal;
