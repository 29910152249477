import moment from "moment";
import React, { useEffect, useState } from "react";
import { Grid, Row } from "rsuite";
import { ReactComponent as DashboardIcon } from "../../../../assets/icons/dashboard.svg";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import SimpleDateRangePickerForManualDataPeriods2 from "../../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import CheckInCheckOut from "../checkInCheckOut";
import ConsumptionCards from "./consumptionCards";
import OccupancyCard from "./occupancyCard";

const buildTransKey = (key: string) => `pages.overview.sections.summary.${key}`;

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const Archives: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const { isMobile } = useWindowSizeState();
  const {
    activeSubscriptionIsStarter,
    hotel: { manualData },
  } = useHotelSubscriptionState();
  const [manualDataIndex, setManualDataIndex] = useState(manualData.length - 1);

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  const [colBreakpoints] = isMobile
    ? [{ xs: 24, sm: 24 }]
    : [{ sm: 6, xxl: 4 }];

  return (
    <div>
      <SecondaryTopBar>
        <Flex row middle gap={8}>
          {dataTypeToggle}
          {manualData.length === 0 ? null : (
            <SimpleDateRangePickerForManualDataPeriods2
              {...{ manualDataIndex, setManualDataIndex }}
            />
          )}
        </Flex>
      </SecondaryTopBar>
      {manualData.length === 0 ? null : (
        <PageSection
          icon={DashboardIcon}
          title={trans(buildTransKey("title"))}
          description={trans(buildTransKey("description"))}
        >
          <div>
            <Grid fluid>
              <Row gutter={16}>
                {!activeSubscriptionIsStarter && selectedManualData && (
                  <CheckInCheckOut
                    period={[
                      moment(selectedManualData.from).toDate(),
                      moment(selectedManualData.to).toDate(),
                    ]}
                    {...{ colBreakpoints }}
                  />
                )}
                <OccupancyCard
                  {...{ colBreakpoints }}
                  manualDataEntry={selectedManualData}
                />
              </Row>
              <Row gutter={16}>
                <ConsumptionCards
                  {...{ colBreakpoints }}
                  manualDataEntry={selectedManualData}
                />
              </Row>
            </Grid>
          </div>
        </PageSection>
      )}
    </div>
  );
};

export default Archives;
