import axios from "axios";
import { has, isUndefined, omit, sum, zip } from "lodash";
import moment, { MomentInput } from "moment";
import { useState } from "react";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tBinUnitSingular } from "../../../interfaces/sensorData";
import tHotel, { tHotelSpaceId } from "../../../models/hotel";
import { MEASURES, tMeasure } from "../../../models/measures";
import { createMockMeasuresBySpace } from "../../../utils/api/mock/measures";
import { constructApiAddress, REQUEST_STATUS } from "../../../utils/apiCall";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import {
  getCO2AndCostsPerMeasure,
  populateMeasuresObj,
} from "../../../utils/measures";
import useEffectSafe from "../../useEffectSafe";
import useGetRequest from "../useGetRequest";
import nUseGetMeasures from "./interfaces";

function useGetMeasures<T extends tMeasure[] = []>(
  hotel: tHotel,
  {
    measures = MEASURES,
    spaces,
    from,
    to,
    binValue,
    binUnit,
    autoBinUnit = false,
  }: {
    measures: tMeasure[];
    spaces?: tHotelSpaceId[];
    from?: MomentInput;
    to?: MomentInput;
    binUnit?: tBinUnitSingular;
    binValue?: number;
    autoBinUnit?: boolean;
  } = {
    measures: MEASURES,
  },
  {
    doRequest = true,
    useLocalApi = false,
    id = undefined,
    mockData = false,
  }: {
    doRequest?: boolean;
    useLocalApi?: boolean;
    id?: string;
    mockData?: boolean;
  } = {
    doRequest: true,
    useLocalApi: false,
    id: undefined,
    mockData: false,
  }
): nUseGetMeasures.tFunctionReturn<T> {
  const { trans } = useLocalizationState();
  const [redo, setRedo] = useState(0);

  const measuresRequest = useGetRequest<
    nUseGetMeasures.tFunctionReturn<T>["data"]
  >(
    {
      // @ts-expect-error
      grouped: {},
      bySpace: {},
      co2: [],
      costs: [],
    },
    {
      ...(doRequest && measures.length ? {} : { status: REQUEST_STATUS.IDLE }),
    }
  );

  const measuresStringified = JSON.stringify(measures.sort());
  const spacesStringified = JSON.stringify((spaces || []).sort());

  useEffectSafe(() => {
    if (doRequest && measures.length) {
      const params: Record<string, any> = {
        ...(isUndefined(spaces)
          ? {}
          : { spaces: spaces.length ? spaces : [""] }),
        measures,
        binnedData: !isUndefined(binValue) && !isUndefined(binUnit),
        binValue,
        binUnit,
      };
      if (from) params.from = moment(from).toISOString();
      if (to) params.to = moment(to).toISOString();

      function handleData(
        dataBySpace: Record<tHotelSpaceId, Record<tMeasure, number[]>>
      ): nUseGetMeasures.tFunctionReturn<T>["data"] {
        const groupedMeasurements: Partial<Record<tMeasure, number[]>> = {};
        Object.values(
          dataBySpace as Record<tHotelSpaceId, Record<tMeasure, number[]>>
        ).forEach((measures) => {
          Object.entries(measures).forEach((entry) => {
            const [measure, values] = entry as [tMeasure, number[]];
            if (!has(groupedMeasurements, measure))
              groupedMeasurements[measure] = [...values];
            else {
              groupedMeasurements[measure] = groupedMeasurements[measure]!.map(
                (value, i) => value + values[i]
              );
            }
          });
        });

        const populated = populateMeasuresObj(
          omit(groupedMeasurements, ["co2", "costs"])
        );
        const co2AndCostsPerMeasure = getCO2AndCostsPerMeasure(
          hotel,
          { ...populated },
          from,
          to
        );

        const bySpace: nUseGetMeasures.tFunctionReturn<T>["data"]["bySpace"] =
          {};
        Object.keys(dataBySpace).forEach((spaceId: tHotelSpaceId) => {
          const co2AndCostsPerMeasure = getCO2AndCostsPerMeasure(
            hotel,
            { ...dataBySpace[spaceId] },
            from,
            to
          );

          // @ts-expect-error
          bySpace[spaceId] = Object.fromEntries(
            MEASURES.filter((m) => has(dataBySpace[spaceId], m)).map((m) => [
              m,
              {
                ...co2AndCostsPerMeasure[m],
                measurements: dataBySpace[spaceId][m],
              },
            ])
          );
        });

        const updatedState = {
          bySpace,
          grouped: Object.fromEntries(
            MEASURES.filter((m) => has(groupedMeasurements, m)).map((m) => [
              m,
              {
                ...co2AndCostsPerMeasure[m],
                measurements: groupedMeasurements[m],
              },
            ])
          ),
          costs: zip(
            co2AndCostsPerMeasure.te?.costs || [],
            co2AndCostsPerMeasure.tw?.costs || []
          ).map(([te, tw]) => sum([te || 0, tw || 0])),
          co2: zip(
            co2AndCostsPerMeasure.te?.co2 || [],
            co2AndCostsPerMeasure.tw?.co2 || []
          ).map(([te, tw]) => sum([te || 0, tw || 0])),
        };

        // @ts-expect-error
        return updatedState;
      }

      measuresRequest.pending();
      if (mockData) {
        const mockData = createMockMeasuresBySpace(
          spaces || [],
          measures,
          from,
          to,
          binUnit,
          binValue
        );
        const updatedState = handleData(mockData);
        measuresRequest.resolve(updatedState);
      } else
        axios
          .get(
            constructApiAddress(
              `/v2/hotels/${hotel._id}/consumption`,
              useLocalApi
            ),
            {
              params,
            }
          )
          .then((res) => {
            try {
              const {
                data: { dataBySpace },
              } = res;

              const updatedState = handleData(dataBySpace);
              measuresRequest.resolve(updatedState);
            } catch (err: any) {
              console.log(err);
              measuresRequest.reject("");
            }
          })
          .catch((err) => {
            measuresRequest.reject(getErrorMessage(err, trans));
          });
    }
  }, [
    from,
    measuresStringified,
    spacesStringified,
    to,
    trans,
    binUnit,
    binValue,
    doRequest,
    autoBinUnit,
    // measures,
    // spaces,
    // measuresRequest,
    useLocalApi,
    // hotel,
    redo,
    mockData,
  ]);

  const redoRequest = () => setRedo((prev) => prev + 1);

  const { data, error, status, isFinal, isLoading, isRejected, isResolved } =
    measuresRequest;

  return {
    data,
    error,
    status,
    isFinal,
    isLoading,
    isRejected,
    isResolved,
    redoRequest,
  };
}

export default useGetMeasures;
