import { floor, has, min, round, sum } from "lodash";
import moment, { MomentInput } from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Table } from "rsuite";
import { ReactComponent as EcoIcon } from "../../assets/icons/eco.svg";
import { ReactComponent as EventRepeatIcon } from "../../assets/icons/event_repeat.svg";
import { ReactComponent as MeetingRoomIcon } from "../../assets/icons/meeting_room.svg";
import { ReactComponent as NorthEastIcon } from "../../assets/icons/north_east.svg";
import { ReactComponent as PaymentsIcon } from "../../assets/icons/payments.svg";
import { ReactComponent as SouthEastIcon } from "../../assets/icons/south_east.svg";
import { ReactComponent as VisibilityIcon } from "../../assets/icons/visibility.svg";
import useHotelState from "../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../hooks/apiRequests/useGetMeasures";
import useScreenSize from "../../hooks/useScreenSize";
import { tHotelSpace } from "../../models/hotel";
import { tMeasureTotal } from "../../models/measures";
import { requestStatusIsFinal } from "../../utils/apiCall";
import { COLORS } from "../../utils/colors";
import { convertMassUnit } from "../../utils/convertUnits/";
import { nUnitConverter } from "../../utils/convertUnits/interfaces";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../utils/tables";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import BaseCell from "../RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell, {
  iSimpleHeaderCellProps,
} from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../Text/Inter";
import SimpleTableWrapper from "../RsuiteWrapper/SimpleTable";

interface iSpaceMeasureConsumptionTableWrappedProps
  extends iSpaceMeasureConsumptionTableProps {
  outletContainerWidth: number;
}

const SpaceMeasureConsumptionTableWrapped: React.FC<
  iSpaceMeasureConsumptionTableWrappedProps
> = ({
  from,
  to,
  outletContainerWidth,
  mainMeasureKey,
  converter,
  tableHeader,
}) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const {
    hotel: { spaces },
    hotel,
  } = useHotelState();

  const mainMeasuresState = useGetMeasures(hotel, {
    measures: [mainMeasureKey],
    from,
    to,
    binUnit: "day",
    binValue: 1,
  });

  const homologousMeasuresRequest = useGetMeasures(hotel, {
    from: moment(from).subtract(1, "year").toISOString(),
    to: moment(to).subtract(1, "year").toISOString(),
    measures: [mainMeasureKey],
  });
  const hoursToSubtract = moment(to).diff(moment(from), "hours");
  const previousMeasuresRequest = useGetMeasures(hotel, {
    measures: [mainMeasureKey],
    from: moment(from).subtract(hoursToSubtract, "hours").toISOString(),
    to: moment(to).subtract(hoursToSubtract, "hours").toISOString(),
  });

  const options = (
    dataKey: string,
    space: tHotelSpace
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "view",
      label: trans(
        "components.consumption_page_specific.space_list.table.options.view_space"
      ),
      icon: VisibilityIcon,
      onClick() {
        navigate(`/spaces/${space._id}`);
      },
    });

    return options;
  };

  type tTableData = tHotelSpace & { isTotal?: boolean };
  const tableData: tTableData[] = [
    {
      _id: "0",
      isAccommodation: true,
      timestamp: new Date(),
      isTotal: true,
      name: trans("general.all_spaces"),
      areaInSquareMeters: undefined,
      measures: {
        ac: { isMeasured: true, add: [], subtract: [] },
        cw: { isMeasured: true, add: [], subtract: [] },
        el: { isMeasured: true, add: [], subtract: [] },
        es: { isMeasured: true, add: [], subtract: [] },
        gw: { isMeasured: true, add: [], subtract: [] },
        hw: { isMeasured: true, add: [], subtract: [] },
        dw: { isMeasured: true, add: [], subtract: [] },
        ntw: { isMeasured: true, add: [], subtract: [] },
        te: { isMeasured: true, add: [], subtract: [] },
        tw: { isMeasured: true, add: [], subtract: [] },
        wh: { isMeasured: true, add: [], subtract: [] },
      },
    },
    ...spaces,
  ];

  const tableHeight =
    (min([5, tableData.length]) || 1) * TABLE_ROW_HEIGHT.M +
    TABLE_HEADER_HEIGHT;

  const spaceWidth = floor(0.15 * outletContainerWidth);
  const valueWidth = floor(0.15 * outletContainerWidth);
  const paymentsWidth = floor(0.15 * outletContainerWidth);
  const ecoWidth = floor(0.15 * outletContainerWidth);
  const lastPeriodWidth = floor(0.15 * outletContainerWidth);
  const homologousWidth = floor(0.15 * outletContainerWidth);

  return (
    <div>
      <SimpleTableWrapper
        id="TABLE.CONSUMPTION.SPACES.LIST"
        height={tableHeight}
        rowHeight={TABLE_ROW_HEIGHT.M}
        headerHeight={TABLE_HEADER_HEIGHT}
        data={tableData}
      >
        <Table.Column width={spaceWidth}>
          <SimpleHeaderCell
            icon={MeetingRoomIcon}
            name={trans("general.space")}
          />
          <SimpleKeyValueCell dataKey="name" />
        </Table.Column>
        <Table.Column width={valueWidth}>
          <SimpleHeaderCell {...tableHeader} />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (!requestStatusIsFinal(mainMeasuresState.status))
                return <Loader size="sm" />;

              let measureValue: ReturnType<nUnitConverter.tFunction>;

              if (tableItem.isTotal) {
                measureValue = converter(
                  sum(
                    mainMeasuresState.data.grouped[mainMeasureKey]!
                      .measurements || []
                  )
                );
              } else {
                if (!has(mainMeasuresState.data.bySpace, tableItem._id))
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                measureValue = converter(
                  sum(
                    mainMeasuresState.data.bySpace[tableItem._id][
                      mainMeasureKey
                    ]?.measurements
                  )
                );
              }

              return (
                <InterTag
                  text={`${round(measureValue.value, 2)} ${
                    measureValue.unitInHTML
                  }`}
                  asHTML
                  size={12}
                  color={COLORS.secondary}
                />
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={ecoWidth}>
          <SimpleHeaderCell
            icon={{ Element: EcoIcon, fill: COLORS.emissions }}
            name={{ text: trans("general.co2_emissions"), asHTML: true }}
          />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (!requestStatusIsFinal(mainMeasuresState.status))
                return <Loader size="sm" />;

              let footprint: any;

              if (tableItem.isTotal) {
                footprint = convertMassUnit(
                  sum(mainMeasuresState.data.grouped[mainMeasureKey]?.co2 || [])
                );
              } else {
                if (!has(mainMeasuresState.data.bySpace, tableItem._id))
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                footprint = convertMassUnit(
                  sum(
                    mainMeasuresState.data.bySpace[tableItem._id][
                      mainMeasureKey
                    ]?.co2 || []
                  )
                );
              }

              return (
                <InterTag
                  text={`${round(footprint.value, 2)} ${footprint.unitInHTML}`}
                  asHTML
                  size={12}
                  color={COLORS.secondary}
                />
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={paymentsWidth}>
          <SimpleHeaderCell
            icon={{ Element: PaymentsIcon, fill: COLORS.esg_environment }}
            name={trans("general.costs")}
          />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (!mainMeasuresState.isResolved) return <Loader size="sm" />;

              let costs: any;

              if (tableItem.isTotal) {
                costs = sum(
                  mainMeasuresState.data.grouped[mainMeasureKey]?.costs || []
                );
              } else {
                if (!has(mainMeasuresState.data.bySpace, tableItem._id))
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                costs = sum(
                  mainMeasuresState.data.bySpace[tableItem._id][mainMeasureKey]
                    ?.costs || []
                );
              }

              return (
                <InterTag
                  text={`${round(costs, 2)} €`}
                  asHTML
                  size={12}
                  color={COLORS.secondary}
                />
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={lastPeriodWidth}>
          <SimpleHeaderCell
            icon={EventRepeatIcon}
            name={trans("general.period_previous")}
          />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (
                !previousMeasuresRequest.isResolved ||
                !mainMeasuresState.isResolved
              )
                return <Loader size="sm" />;

              let measureValue: any;
              let measureValuePrevious: any;

              if (tableItem.isTotal) {
                measureValuePrevious = converter(
                  sum(
                    previousMeasuresRequest.data.grouped[mainMeasureKey]!
                      .measurements || []
                  )
                );

                measureValue = converter(
                  sum(
                    mainMeasuresState.data.grouped[mainMeasureKey]!
                      .measurements || []
                  )
                );
              } else {
                if (
                  !has(mainMeasuresState.data.bySpace, tableItem._id) ||
                  !has(previousMeasuresRequest.data.bySpace, tableItem._id)
                )
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                measureValuePrevious = converter(
                  sum(
                    previousMeasuresRequest.data.bySpace[tableItem._id][
                      mainMeasureKey
                    ]?.measurements || []
                  )
                );

                measureValue = converter(
                  sum(
                    mainMeasuresState.data.bySpace[tableItem._id][
                      mainMeasureKey
                    ]?.measurements || []
                  )
                );
              }

              const diff = measureValuePrevious.value - measureValue.value;

              const value = round(
                (Math.abs(diff) / measureValuePrevious.value) * 100,
                2
              );

              if (diff === 0)
                return <InterTag text={"0 %"} size={12} color={COLORS.gray} />;

              if (diff < 0)
                return (
                  <Flex row gap={4}>
                    <InterTag
                      text={`${value} %`}
                      size={12}
                      color={COLORS.error}
                    />
                    <Icon
                      Element={NorthEastIcon}
                      size={12}
                      fill={COLORS.error}
                    />
                  </Flex>
                );

              return (
                <Flex row gap={4}>
                  <InterTag
                    text={`${value} %`}
                    size={12}
                    color={COLORS.success}
                  />
                  <Icon
                    Element={SouthEastIcon}
                    size={12}
                    fill={COLORS.success}
                  />
                </Flex>
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={homologousWidth}>
          <SimpleHeaderCell
            icon={EventRepeatIcon}
            name={trans("general.period_homologue")}
          />
          <BaseCell>
            {(tableItem: tTableData) => {
              if (
                !homologousMeasuresRequest.isResolved ||
                !mainMeasuresState.isResolved
              )
                return <Loader size="sm" />;

              let measureValue: any;
              let measureValueHomologous: any;

              if (tableItem.isTotal) {
                measureValue = converter(
                  sum(
                    mainMeasuresState.data.grouped[mainMeasureKey]!
                      .measurements || []
                  )
                );
                measureValueHomologous = converter(
                  sum(
                    homologousMeasuresRequest.data.grouped[mainMeasureKey]!
                      .measurements || []
                  )
                );
              } else {
                if (
                  !has(mainMeasuresState.data.bySpace, tableItem._id) ||
                  !has(homologousMeasuresRequest.data.bySpace, tableItem._id)
                )
                  return (
                    <InterTag
                      text={trans("general.no_data_to_show")}
                      size={12}
                      color={COLORS.secondary}
                    />
                  );

                measureValue = converter(
                  sum(
                    mainMeasuresState.data.bySpace[tableItem._id][
                      mainMeasureKey
                    ]?.measurements || []
                  )
                );

                measureValueHomologous = converter(
                  sum(
                    homologousMeasuresRequest.data.bySpace[tableItem._id][
                      mainMeasureKey
                    ]?.measurements || []
                  )
                );
              }

              const diff = measureValueHomologous.value - measureValue.value;

              if (diff === 0)
                return <InterTag text={"0 %"} size={12} color={COLORS.gray} />;

              const value = round(
                (Math.abs(diff) / measureValueHomologous.value) * 100,
                2
              );

              if (diff < 0)
                return (
                  <Flex row gap={4}>
                    <InterTag
                      text={`${value} %`}
                      size={12}
                      color={COLORS.error}
                    />
                    <Icon
                      Element={NorthEastIcon}
                      size={12}
                      fill={COLORS.error}
                    />
                  </Flex>
                );

              return (
                <Flex row gap={4}>
                  <InterTag
                    text={`${value} %`}
                    size={12}
                    color={COLORS.success}
                  />
                  <Icon
                    Element={SouthEastIcon}
                    size={12}
                    fill={COLORS.success}
                  />
                </Flex>
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column flexGrow={1} align="right">
          <SimpleHeaderCell />
          <SimpleActionCell options={options} dataKey="_id" />
        </Table.Column>
      </SimpleTableWrapper>
    </div>
  );
};

interface iSpaceMeasureConsumptionTableProps {
  from: MomentInput;
  to: MomentInput;
  mainMeasureKey: tMeasureTotal;
  converter: any;
  tableHeader: iSimpleHeaderCellProps;
}

const SpaceMeasureConsumptionTable: React.FC<
  iSpaceMeasureConsumptionTableProps
> = (props) => {
  const { outlet: oc } = useScreenSize();

  if (!oc) return null;

  return (
    <SpaceMeasureConsumptionTableWrapped
      {...props}
      outletContainerWidth={oc.width}
    />
  );
};

export default SpaceMeasureConsumptionTable;
