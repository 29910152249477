import axios from "axios";
import { cloneDeep, find, isUndefined, uniqueId } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  IconButton,
  Input,
  Modal,
  ModalProps,
  SelectPicker,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as MoreVertIcon } from "../../../assets/icons/more_vert.svg";
import { ReactComponent as WorkspacesIcon } from "../../../assets/icons/workspaces.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSection from "../../../components/PageSection";
import SimpleTagPicker from "../../../components/RsuiteWrapper/SimpleTagPicker";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePutRequest, {
  tUsePutRequest,
} from "../../../hooks/apiRequests/usePutRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import {
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
  tHotelSpaceId,
} from "../../../models/hotel";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

const buildTransKey = (keyword: string) =>
  `pages.spaces.modals.space_aggregates.${keyword}`;

const constructSpaceAggregateTransKey = (
  category: tHotelSpaceAggregate["category"],
  plural: boolean = false
) => `general.space_categories_.${plural ? "extended" : "short"}.${category}`;

type tData = {
  _id: string;
  name: string;
  spaces: tHotelSpaceId[];
  category: tHotelSpaceAggregate["category"];
};

type tErrors = Record<keyof tData, string>;

const initStateData = (name: string): tData => ({
  _id: uniqueId(),
  name,
  category: "group",
  spaces: [],
});

const initStateErrors = (): tErrors => ({
  _id: "",
  name: "",
  category: "",
  spaces: "",
});

interface iSpaceAggregateModalWrappedProps
  extends Pick<iSpaceAggregateModalProps, "aggId" | "aggName" | "onClose"> {
  confirmRequest: tUsePutRequest;
}

const SpaceAggregateModalWrapped: React.FC<
  iSpaceAggregateModalWrappedProps
> = ({ confirmRequest, onClose, aggId, aggName }) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotelId, hotel, findSpaceAggregate } = useHotelSpacesState();
  const { updateHotel, updatingHotel } = useHotelDispatch();

  const [state2, setState2] = useState<{
    selected: number;
    items: {
      data: tData;
      errors: tErrors;
    }[];
  }>({
    selected: 0,
    items: [
      {
        data: initStateData(`${trans("general.name")} #${uniqueId()}`),
        errors: initStateErrors(),
      },
    ],
  });

  useEffect(() => {
    if (aggId) {
      const agg = findSpaceAggregate(aggId);
      if (!agg) {
        toaster.error(
          trans("general.not_found_x", { parameters: [aggName || aggId] })
        );
        onClose();
        return;
      }
      setState2({
        selected: 0,
        items: [
          {
            data: {
              _id: agg._id,
              name: agg.name,
              category: agg.category,
              spaces: cloneDeep(agg.spaces),
            },
            errors: {
              _id: "",
              name: "",
              category: "",
              spaces: "",
            },
          },
        ],
      });
    }
  }, [aggId, aggName, onClose, toaster, trans, findSpaceAggregate]);

  const handleConfirm = () => {
    confirmRequest.pending();
    updatingHotel();
    (aggId
      ? axios.patch(
          constructApiAddress(
            `/v2/hotels/${hotelId}/space-aggregates/${aggId}`,
            false
          ),
          {
            name: state2.items[state2.selected].data.name,
            spaces: state2.items[state2.selected].data.spaces,
            category: state2.items[state2.selected].data.category,
          }
        )
      : axios.post(
          constructApiAddress(`/v2/hotels/${hotelId}/space-aggregates`, false),
          {
            items: state2.items.map(({ data }) => ({
              name: data.name,
              spaces: data.spaces,
              category: data.category,
            })),
          }
        )
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;

        if (aggId) {
          toaster.success(
            trans("general.updated_x", {
              parameters: [
                trans(
                  constructSpaceAggregateTransKey(
                    `${state2.items[0].data.category}`
                  )
                ),
              ],
            })
          );
        } else {
          if (state2.items.length === 1) {
            toaster.success(
              trans("general.created_x", {
                parameters: [
                  trans(
                    constructSpaceAggregateTransKey(
                      state2.items[0].data.category
                    )
                  ),
                ],
              })
            );
          } else {
            toaster.success(
              trans("general.created_multiple_x", {
                parameters: [trans("general.space_categories")],
              })
            );
          }
        }

        confirmRequest.resolve();
        updateHotel(hotelId, hotel);
        onClose();
      })
      .catch((err) => {
        let error: string | null = null;
        try {
          const {
            data: { failedSpaces },
          } = err.response;
          if (err.response.status === 409) {
            error = trans(buildTransKey("errors.repeated_names"));
            setState2((prev) => {
              const errors = prev.items[prev.selected].errors;
              errors.name = error as string;
              return { ...prev };
            });
          }
          if (failedSpaces && failedSpaces.length) {
            error = `${trans(buildTransKey(".errors.spaces_dont_exist"), {
              parameters: [
                failedSpaces
                  .map((_id: tHotelSpaceId) =>
                    find(hotel.spaces, (s) => s._id === _id)
                  )
                  .filter((v: any) => v)
                  .map((v: tHotelSpace) => v.name)
                  .join(", "),
              ],
            })}`;
            setState2((prev) => {
              const errors = prev.items[prev.selected].errors;
              errors.spaces = error as string;
              return { ...prev };
            });
          }
          if (!error) {
            error = getErrorMessage(err, trans);
            toaster.error(error as string);
          }
        } catch (err) {
          console.log(err);
        }
        updateHotel(hotelId, {});
        confirmRequest.reject(error || "", true);
      });
  };

  const renderTabs = () => {
    const newTab = () => {
      if (state2.items.length === 20) {
        toaster.error(
          trans(buildTransKey("notifications.max_number_of_items"))
        );
        return;
      }
      setState2((prev) => ({
        ...prev,
        items: [
          ...prev.items,
          {
            data: initStateData(`${trans("general.name")} #${uniqueId()}`),
            errors: initStateErrors(),
          },
        ],
        selected: prev.items.length,
      }));
    };

    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "remove-all-but-1",
        label: trans("components.tab.options.remove_all_but_1"),
        onClick: () => {
          setState2((prev) => {
            const item = prev.items[prev.selected];
            return { ...prev, items: [item], selected: 0 };
          });
        },
        show: state2.items.length > 1,
      },
    ];
    return (
      <div
        style={{ width: "100%", borderBottom: `${COLORS.gray_200} 1px solid` }}
        className={"horizontal-scrollbar"}
      >
        <Flex row>
          <Flex style={{ overflow: "auto", maxWidth: "90%" }} row>
            {state2.items.map((item, i) => {
              const hasErrors =
                Object.values(item.errors).filter((v) => v && v.length).length >
                0;
              return (
                <Flex
                  middle
                  center
                  row
                  color={
                    state2.selected === i ? COLORS.gray_200 : COLORS.gray_50
                  }
                  gap={4}
                  key={item.data._id}
                  onClick={() => {
                    setState2((prev) => ({ ...prev, selected: i }));
                  }}
                  size="xs"
                  className="hover-gray-200"
                  style={{
                    padding: "4px 8px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  {hasErrors && <Badge />}
                  <InterTag
                    text={trans(item.data.name)}
                    size={12}
                    color={COLORS.secondary}
                  />

                  {state2.items.length > 1 && (
                    <IconButton
                      className="hover-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setState2((prev) => {
                          const items = prev.items;
                          items.splice(i, 1);
                          return {
                            ...prev,
                            items: [...items],
                            selected:
                              prev.selected >= items.length
                                ? prev.selected - 1
                                : prev.selected,
                          };
                        });
                      }}
                      style={{ padding: "3px" }}
                      circle
                      size="xs"
                      appearance="subtle"
                      icon={
                        <Icon
                          Element={CloseIcon}
                          fill={COLORS.secondary}
                          size={12}
                        />
                      }
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
          <Flex middle center row gap={4} style={{ width: "10%" }}>
            <IconButton
              size="xs"
              icon={
                <Icon Element={AddIcon} fill={COLORS.secondary} size={16} />
              }
              onClick={newTab}
            />
            {options.filter((o) => o.show).length > 0 && (
              <SimpleWhisperPopoverDropdown options={options}>
                <IconButton
                  size="xs"
                  icon={
                    <Icon
                      Element={MoreVertIcon}
                      fill={COLORS.secondary}
                      size={16}
                    />
                  }
                />
              </SimpleWhisperPopoverDropdown>
            )}
          </Flex>
        </Flex>
      </div>
    );
  };

  const { data: activeData, errors: activeErrors } =
    state2.items[state2.selected];

  const confirmButtonDisabled =
    state2.items.filter(
      (i) => Object.values(i.errors).filter((v) => v.length).length > 0
    ).length > 0;

  return (
    <Fragment>
      <Modal.Header closeButton={!confirmRequest.isLoading}></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={32}>
          <PageSection
            title={
              aggId
                ? trans("general.update_x", {
                    parameters: [
                      trans(
                        constructSpaceAggregateTransKey(
                          state2.items[0].data.category
                        )
                      ),
                    ],
                  })
                : trans("general.add_x", {
                    parameters: [trans("general.space_categories")],
                  })
            }
            icon={{ Element: WorkspacesIcon }}
          />
          {isUndefined(aggId) && (
            <Flex column gap={8}>
              {renderTabs()}
              <Flex column gap={4}></Flex>
            </Flex>
          )}
          <Flex column gap={8} style={{ width: "50%" }}>
            <InputWrapper
              label={trans("general.category")}
              error={activeErrors.category}
            >
              <SelectPicker
                cleanable={false}
                value={activeData.category}
                disabled={confirmRequest.isLoading}
                onChange={(value) => {
                  if (value)
                    setState2((prev) => {
                      const selectedItem = prev.items[prev.selected];

                      const errorText = trans(
                        buildTransKey("errors.name_in_use"),
                        {
                          parameters: [
                            trans(
                              `general.space_categories_.short.${selectedItem.data.category}`
                            ),
                          ],
                        }
                      );
                      let nameRepeated = false;
                      for (const item of prev.items) {
                        if (
                          item.data.name === selectedItem.data.name &&
                          item.data.category === value
                        ) {
                          nameRepeated = true;
                          item.errors.name = errorText;
                        } else {
                          item.errors.name = "";
                        }
                      }
                      for (const item of hotel.spaceAggregates) {
                        if (
                          item.name === selectedItem.data.name &&
                          item.category === value
                        ) {
                          nameRepeated = true;
                        }
                      }

                      if (nameRepeated) {
                        selectedItem.errors.name = errorText;
                      } else selectedItem.errors.name = "";

                      selectedItem.data.category = value;

                      return { ...prev, items: [...prev.items] };
                    });
                }}
                data={(
                  [
                    "group",
                    "type",
                    "zone",
                  ] as tHotelSpaceAggregate["category"][]
                ).map((cat) => ({
                  label: trans(`general.space_categories_.extended.${cat}`),
                  value: cat,
                }))}
              />
            </InputWrapper>
            <InputWrapper
              label={trans("general.name")}
              error={activeErrors.name}
            >
              <Input
                disabled={confirmRequest.isLoading}
                type="text"
                size="md"
                value={activeData.name}
                onChange={(value) => {
                  const selectedData = state2.items[state2.selected].data;

                  setState2((prev) => {
                    const errorText = trans(
                      buildTransKey("errors.name_in_use"),
                      {
                        parameters: [
                          trans(
                            `general.space_categories_.short.${selectedData.category}`
                          ),
                        ],
                      }
                    );
                    const item = prev.items[prev.selected];
                    let nameRepeated = false;
                    for (const item of prev.items) {
                      if (
                        item.data.name === value &&
                        item.data.category === selectedData.category
                      ) {
                        nameRepeated = true;
                        item.errors.name = errorText;
                      } else {
                        item.errors.name = "";
                      }
                    }
                    for (const item of hotel.spaceAggregates) {
                      if (
                        item.name === value &&
                        item.category === selectedData.category
                      ) {
                        nameRepeated = true;
                      }
                    }

                    if (nameRepeated) {
                      item.errors.name = errorText;
                    } else item.errors.name = "";

                    item.data.name = value;
                    return { ...prev, items: [...prev.items] };
                  });
                }}
                placeholder={trans(
                  "pages.spaces.modals.space_aggregates.name.placeholder"
                )}
              />
            </InputWrapper>

            <InputWrapper
              label={trans("general.spaces")}
              error={activeErrors.spaces}
            >
              <SimpleTagPicker
                disabled={confirmRequest.isLoading}
                onChange={(value) => {
                  setState2((prev) => {
                    const data = prev.items[prev.selected].data;
                    data.spaces = value;
                    return { ...prev, items: [...prev.items] };
                  });
                }}
                value={activeData.spaces}
                data={hotel.spaces.map(({ name, _id }) => ({
                  value: _id,
                  label: name,
                }))}
              />
            </InputWrapper>
          </Flex>
          <Modal2ActionButtons
            onClose={onClose}
            onConfirm={handleConfirm}
            isLoading={confirmRequest.isLoading}
            confirmButtonDisabled={confirmButtonDisabled}
          />
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iSpaceAggregateModalProps extends ModalProps {
  onClose(): void;
  aggId?: tHotelSpaceAggregateId;
  aggName?: string;
}

const SpaceAggregateModal: React.FC<iSpaceAggregateModalProps> = ({
  aggId,
  aggName,
  onClose,
  ...props
}) => {
  const confirmRequest = usePutRequest();

  return (
    <Modal
      overflow={false}
      keyboard={false}
      backdrop={confirmRequest.isLoading ? "static" : true}
      id="space-agg-modal"
      size="md"
      {...{
        ...props,
        onClose,
      }}
    >
      <SpaceAggregateModalWrapped
        {...{
          confirmRequest,
          onClose,
          aggId,
          aggName,
        }}
      />
    </Modal>
  );
};

export default SpaceAggregateModal;
