import React, { PropsWithChildren } from "react";
import { Tooltip, Whisper } from "rsuite";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";
import { tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import ReloadButton from "../../Buttons/ReloadButton";
import StraightComparison, {
  iStraightComparisonProps,
} from "../../Comparions/straightComparison";
import Flex from "../../Flex";
import Icon from "../../Icons/Icon";
import { toIconProps } from "../../Icons/Icon/utils";
import IconBg, { iIconBgProps } from "../../Icons/IconBg";
import SkeletonText from "../../Skeleton/SkeletonText";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

interface iProps {
  icon: tIconElement | iIconBgProps;
  label: string | iTextProps;
  value?: number | string | iTextProps;
  valueLoading?: boolean;
  error?: string;
  reload?: boolean;
  onReload?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  comparison?: iStraightComparisonProps;
  comparisonLoading?: boolean;
  hover?: boolean;
  [key: string]: any;
}

const BasicCard: React.FC<PropsWithChildren<iProps>> = ({
  icon,
  label,
  value,
  valueLoading,
  comparison,
  comparisonLoading,
  hover,
  children,
  error,
  reload,
  onReload,
  ...props
}) => {
  const textProps = {
    size: 20,
    bold: true,
    color: COLORS.secondary,
    ...toTextProps(value || ""),
  };
  return (
    <Flex
      middle
      row
      gap={16}
      className={[hover && "hover-box-shadow"].filter((v) => v).join(" ")}
      {...{
        ...props,
        style: {
          cursor: hover ? "pointer" : "default",
          backgroundColor: COLORS.white,
          padding: "16px",
          borderRadius: "12px",
          ...props.style,
        },
      }}
    >
      <IconBg {...{ size: 32, circle: true, ...toIconProps(icon) }} />
      <Flex column gap={8} center one>
        <InterTag
          {...{ size: 14, color: COLORS.secondary, ...toTextProps(label) }}
        />
        {error ? (
          <Flex row gap={4} middle>
            <Whisper
              trigger="hover"
              placement="topStart"
              speaker={<Tooltip>{error}</Tooltip>}
            >
              <Flex center middle>
                <Icon Element={WarningIcon} fill={COLORS.warning} size={24} />
              </Flex>
            </Whisper>
            {reload && onReload && <ReloadButton onClick={onReload} />}
          </Flex>
        ) : (
          <Flex column gap={4}>
            {valueLoading ? (
              <SkeletonText width={100} height={textProps.size} />
            ) : (
              <InterTag
                {...{
                  ...textProps,
                  style: {
                    ...textProps.style,
                  },
                }}
              />
            )}
            {comparisonLoading ? (
              <SkeletonText width={100} height={14} />
            ) : (
              comparison && (
                <StraightComparison
                  {...{ unitSize: 14, valueSize: 14, ...comparison }}
                />
              )
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default BasicCard;
