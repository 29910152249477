import { useEffect, useState } from "react";

const useEffectSafe = (
  run: () => void,
  dependencies: any[],
  timeout: number = 300
) => {
  const [realtimeout] = useState<number>(() => {
    return process.env.NODE_ENV === "production" ? 0 : timeout;
  });

  useEffect(() => {
    if (realtimeout === 0) run();
    else {
      const t = setTimeout(() => {
        run();
      }, realtimeout);
      return () => {
        clearTimeout(t);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};

export default useEffectSafe;
