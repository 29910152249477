import React, { PropsWithChildren } from "react";
import Flex from "../../../components/Flex";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

interface iProps {}

const buildTransKey = (key: string) => `pages.awards.sections.list.${key}`;

const NoAwards: React.FC<PropsWithChildren<iProps>> = ({ children }) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column middle gap={40}>
      <img
        src="https://noytrall-property-app-assets.s3.eu-west-1.amazonaws.com/Gift-amico+2.svg"
        alt="no awards"
      />
      <Flex column middle gap={16} style={{ margin: "0 70px" }}>
        <InterTag
          size={36}
          text={trans(buildTransKey("no_awards.title"))}
          color={COLORS.secondary}
        />
        {[
          trans(buildTransKey("no_awards.header_description")),
          ...[0, 1, 2].map(
            (i) =>
              `<strong>${trans(
                buildTransKey(`no_awards.points[${i}].title`)
              )}</strong>: ${trans(
                buildTransKey(`no_awards.points[${i}].description`)
              )}`
          ),
          trans(buildTransKey("no_awards.footer_description")),
        ].map((text) => (
          <InterTag
            key={text}
            asHTML
            size={16}
            color={COLORS.secondary}
            style={{
              textWrap: "wrap",
              textAlign: "center",
              lineHeight: "22px",
            }}
            text={text}
          />
        ))}
      </Flex>
      {children}
    </Flex>
  );
};

export default NoAwards;
