import axios from "axios";
import React, { Fragment, useState } from "react";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { constructApiAddress } from "../../../utils/apiCall";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import CreateEditAwardModal, { tDataOnSubmit } from "./createEditAwardModal";

interface iProps {}

const AddAward: React.FC<iProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { updateHotel } = useHotelDispatch();
  const { hotelId } = useHotelState();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();

  const createAward = (award: tDataOnSubmit) => {
    postRequest.pending();
    const {
      descriptions,
      discount,
      discountType,
      type,
      validityAfterAwarding,
    } = award;

    axios
      .post(constructApiAddress(`/v2/hotels/${hotelId}/awards/`, false), {
        type,
        discount,
        discountType,
        validityAfterAwarding,
        descriptions,
      })
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        toaster.success(trans("pages.awards.notifications.award_created"));
        postRequest.resolve();
        setModal({ open: false });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error);
        toaster.error(error);
      });
  };

  return (
    <Fragment>
      <CreateEditAwardModal
        onRequest={createAward}
        requestStatus={postRequest.status}
        requestError={postRequest.error}
        open={modal.open}
        onClose={() => setModal({ open: false })}
      />
      <SimpleAddButton onClick={() => setModal({ open: true })} />
    </Fragment>
  );
};

export default AddAward;
