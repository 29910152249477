import React from "react";
import { COLORS, hex2hexA } from "../../../utils/colors";
import Flex from "../../Flex";
import Icon, { tIconProps } from "../Icon";

export interface iIconBgProps extends tIconProps {
  backgroundColor?: string;
  backgroundTrasparency?: number;
  circle?: boolean;
}

const IconBg: React.FC<iIconBgProps> = ({
  backgroundColor,
  circle,
  backgroundTrasparency = 10,
  ...props
}) => {
  const bgColor =
    backgroundColor ||
    hex2hexA(props.fill || COLORS.secondary, backgroundTrasparency);

  return (
    <Flex
      center
      middle
      color={bgColor}
      style={{ padding: "8px", ...(circle ? { borderRadius: "50%" } : {}) }}
    >
      <Icon {...props} />
    </Flex>
  );
};

export default IconBg;
