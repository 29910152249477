import { find, last, sortBy } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleDateRangePickerForManualDataPeriods from "../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelsListState from "../../../context/Hotel/hooks/hotelState/useHotelsListState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import useEffectSafe from "../../../hooks/useEffectSafe";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotelId, tHotelManualData } from "../../../models/hotel";
import Archives from "./archives";
import Live from "./live";
import TableOfProperties from "./table";

const PropertiesWrapped: React.FC = () => {
  const { hotels } = useHotelsListState();

  const [period, setPeriod] = usePeriodState();
  const [periodArchives, setPeriodArchives] = usePeriodState();
  const [sortedManualData, setSortedManualData] = useState<
    (tHotelManualData & { hotelId: tHotelId })[]
  >([]);

  useEffectSafe(() => {
    const all = hotels.flatMap((h) =>
      h.manualData.map((md) => ({ ...md, hotelId: h._id }))
    );
    const filtered = all.filter((elem, i, array) => {
      const found = find(array.slice(i + 1), (item) =>
        moment(item.from).isSame(moment(elem.from))
      );

      return found === undefined;
    });

    const sorted = sortBy(filtered, (md) => moment(md.from).valueOf());

    setSortedManualData(sorted);
    const lastMD = last(sorted);
    if (lastMD) setPeriodArchives([lastMD.from, lastMD.to]);
    else
      setPeriodArchives([
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ]);
  }, []);

  const dataTypeState = useDataTypeToggle();

  return <TableOfProperties />;

  if (!dataTypeState.isResolved) return null;

  const [mainComponent, dateRangeComponent] = dataTypeState.dataTypeLive
    ? [
        <Live {...{ period }} />,
        <SimpleDateRangePicker
          value={period}
          onChange={setPeriod}
          style={{ paddingLeft: "8px" }}
        />,
      ]
    : [
        <Archives {...{ period: periodArchives }} />,
        <SimpleDateRangePickerForManualDataPeriods
          {...{
            setPeriod: setPeriodArchives,
            period: periodArchives,
            manualData: sortedManualData,
          }}
        />,
      ];

  return (
    <div>
      <SecondaryTopBar>
        <Flex row middle gap={8}>
          <DataTypeToggle {...{ dataTypeState }} />
          {dateRangeComponent}
        </Flex>
      </SecondaryTopBar>
      {mainComponent}
    </div>
  );
};

const Properties: React.FC = () => {
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();

  const names = profile.name.split(" ");

  return (
    <>
      <TopBar
        page={{
          text: `${trans("pages.properties.title", {
            parameters: [
              names.length > 1 ? names.slice(0, -1).join(" ") : names[0],
            ],
          })} &#128075;`,
          asHTML: true,
        }}
      />
      <PropertiesWrapped />
      <PageBottomPadding />
    </>
  );
};

export default Properties;
