import React, { PropsWithChildren } from "react";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../../utils/colors";

interface iProps {}

const NoReservations: React.FC<PropsWithChildren<iProps>> = ({ children }) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column middle gap={40}>
      <img
        src={
          "https://noytrall-property-app-assets.s3.eu-west-1.amazonaws.com/breakfast+from+bed-amico+2.svg"
        }
        alt="Breakfast in bed"
      />
      <Flex column middle gap={16} style={{ margin: "0 70px" }}>
        <InterTag
          size={36}
          text={trans("pages.guests.no_stays.title")}
          color={COLORS.secondary}
        />
        {[
          trans("pages.guests.no_stays.header_description"),
          trans("pages.guests.no_stays.todo.description"),
          ...["check_systems", "check_later", "add_stay", "reach_out"].map(
            (key) =>
              `<strong>${trans(
                `pages.guests.no_stays.todo.${key}.label`
              )}</strong>: ${trans(
                `pages.guests.no_stays.todo.${key}.description`
              )}`
          ),
          trans("pages.guests.no_stays.footer_description"),
        ].map((text) => (
          <InterTag
            key={text}
            asHTML
            size={16}
            color={COLORS.secondary}
            style={{
              textWrap: "wrap",
              textAlign: "center",
              lineHeight: "22px",
            }}
            text={text}
          />
        ))}
      </Flex>
      {children}
    </Flex>
  );
};

export default NoReservations;
