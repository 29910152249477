import axios from "axios";
import React, { useState } from "react";
import { InlineEdit, Input, Loader } from "rsuite";
import { ReactComponent as StoreFrontIcon } from "../../../assets/icons/storefront.svg";
import Flex from "../../../components/Flex";
import PageSection from "../../../components/PageSection";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePatchRequest from "../../../hooks/apiRequests/usePostRequest";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

const MAX_NAME_LENGTH = 60;

interface EditNameWrappedProps extends iProps {}

const EditNameWrapped: React.FC<EditNameWrappedProps> = ({ canWrite }) => {
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [name, setName] = useState(() => hotel.guestApp.name || "");

  const request = usePatchRequest();

  const onSave = (event: any) => {
    if (!canWrite) return;
    if (name.length < 3) {
      return setName(hotel.guestApp.name || "");
    }
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      if (name !== hotel.guestApp.name) {
        request.pending();
        axios
          .patch(
            constructApiAddress(`/v2/hotels/${hotelId}/guest-app/`, false),
            {
              name,
            }
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(hotelId, hotel);
            request.resolve(
              undefined,
              trans(
                "pages.hotel_profile.sections.name.notifications.name_changed"
              )
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            request.reject(error, true);
            setName(hotel.guestApp.name || "");
          });
      }
      return;
    }
    return setName(hotel.guestApp.name || "");
  };

  return (
    <Flex column gap={8}>
      {request.isLoading ? (
        <Flex
          style={{
            height: "34px",
            backgroundColor: COLORS.gray_300,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
            paddingLeft: "8px",
          }}
          middle
        >
          <Loader size="sm" />
        </Flex>
      ) : (
        <InlineEdit
          disabled={!canWrite}
          className="inter"
          style={{
            backgroundColor: canWrite ? COLORS.white : COLORS.gray_200,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
          }}
          value={name}
          onChange={(value) => setName(value.slice(0, MAX_NAME_LENGTH))}
          onSave={onSave}
          onCancel={() => setName(hotel.guestApp.name || "")}
        >
          <Input />
        </InlineEdit>
      )}
      <InterTag
        size={12}
        color={COLORS.gray_400}
        text={trans("pages.hotel_profile.sections.name.characters", {
          parameters: [name.length, MAX_NAME_LENGTH],
        })}
      />
    </Flex>
  );
};

interface iProps {
  canWrite: boolean;
}

const EditName: React.FC<iProps> = (props) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.hotel_profile.sections.name.title")}
        icon={StoreFrontIcon}
        description={trans("pages.hotel_profile.sections.name.description")}
      />
      <EditNameWrapped {...props} />
    </Flex>
  );
};

export default EditName;
