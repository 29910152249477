import React from "react";
import Flex from "../../../components/Flex";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

interface iProps {
  label: string;
}

const NoChallenges: React.FC<iProps> = ({ label }) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column middle gap={40}>
      <img
        src="https://noytrall-property-app-assets.s3.eu-west-1.amazonaws.com/Achievement-amico+2.svg"
        alt="no challenges"
      />
      <Flex column middle gap={16} style={{ margin: "0 70px" }}>
        <InterTag
          size={36}
          text={trans("pages.challenges.sections.list.no_challenges.title")}
          color={COLORS.secondary}
        />
        {[
          trans(
            "pages.challenges.sections.list.no_challenges.header_description",
            {
              parameters: [label],
            }
          ),
          ...[0, 1].map(
            (i) =>
              `<strong>${trans(
                `pages.challenges.sections.list.no_challenges.points[${i}].title`
              )}</strong>: ${trans(
                `pages.challenges.sections.list.no_challenges.points[${i}].description`
              )}`
          ),

          trans(
            "pages.challenges.sections.list.no_challenges.footer_description"
          ),
        ].map((text) => (
          <InterTag
            key={text}
            asHTML
            size={16}
            color={COLORS.secondary}
            style={{
              textWrap: "wrap",
              textAlign: "center",
              lineHeight: "22px",
            }}
            text={text}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default NoChallenges;
