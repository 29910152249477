import React, { useContext } from "react";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import ConservationLabsEvents from "../../../../components/ConservationLabs/ConservationLabsEvents";
import Flex from "../../../../components/Flex";
import MeasureConsumption from "../../../../components/MeasureConsumption";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import PageSection from "../../../../components/PageSection";
import RoadmapTable from "../../../../components/RoadmapTable";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SpaceMeasureConsumptionTable from "../../../../components/Tables/SpaceMeasureConsumptionTable";
import InterTag from "../../../../components/Text/Inter";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import { MEASURES_WATER_SPECIFIC, tMeasureWaterSpecific, TW } from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { convertVolumeUnit, DEFAULT_WATER_MEASURE_UNIT } from "../../../../utils/convertUnits";
import { ConsumptionContext } from "../../consumption/context";

const buildTransKey = (key: string) => `pages.water.live.sections.${key}`;

const LiveData: React.FC<{ dataTypeToggle: JSX.Element | null }> = ({ dataTypeToggle }) => {
  const {
    setDataTypeToggleDisabled,
    state: { dataTypeToggleDisabled },
  } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces },
  } = useHotelMeasuresTrackedState();

  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  const specificMeasures: { measureKey: tMeasureWaterSpecific }[] = [];
  MEASURES_WATER_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  const disableInputs =
    dataTypeToggleDisabled.electricityMeasuresRequest ||
    dataTypeToggleDisabled.waterMeasuresRequest;

  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8} middle>
          {dataTypeToggle}
          <SimpleDateRangePicker disabled={disableInputs} onChange={setPeriod} value={period} />
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        {trackedMeasures.tw ? (
          <>
            <PageSection
              {...{
                title: trans(buildTransKey("overview.title")),
                description: trans(buildTransKey("overview.description")),
                icon: QueryStatsIcon,
              }}
            >
              <MeasureConsumption
                measuresRequestStatusTracker={(status) => {
                  setDataTypeToggleDisabled({
                    electricityMeasuresRequest: status === REQUEST_STATUS.PENDING,
                  });
                }}
                mainMeasureKey={TW}
                specificMeasures={specificMeasures}
                converter={convertVolumeUnit}
                mainUnit={DEFAULT_WATER_MEASURE_UNIT}
                binUnit={"day"}
                binValue={1}
                from={period[0]}
                to={period[1]}
              />
            </PageSection>
            <PageSection
              {...{
                title: trans(buildTransKey("list.title")),
                description: trans(buildTransKey("list.description")),
                icon: { Element: WaterDropIcon, fill: COLORS.water },
              }}
            >
              <SpaceMeasureConsumptionTable
                mainMeasureKey="tw"
                converter={convertVolumeUnit}
                from={period[0]}
                to={period[1]}
                {...{
                  tableHeader: {
                    icon: { Element: WaterDropIcon, fill: COLORS.water },
                    name: trans("general.measures_.water"),
                  },
                }}
              />
            </PageSection>
            <PageSection title={trans(buildTransKey("events.title"))}>
              <ConservationLabsEvents from={period[0]} to={period[1]} />
            </PageSection>
          </>
        ) : (
          <Flex>
            <InterTag
              text={trans(buildTransKey("not_measured"))}
              size={16}
              color={COLORS.secondary}
            />
          </Flex>
        )}
        <RoadmapTable filterByType={"water"} />
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default LiveData;
