import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, InlineEdit, Loader } from "rsuite";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import { constructApiAddress } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import styles from "../styles.module.css";
import ChangePasswordModal from "./changePasswordModal";

interface iChangeProps {
  property: "name" | "email";
  labelTransKey: string;
}

const Change: React.FC<iChangeProps> = ({ property, labelTransKey }) => {
  const putRequest = usePutRequest();
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const { updateProfile } = useProfileDispatch();
  const [input, setInput] = useState("");

  const initValue = profile[property];

  useEffect(() => {
    setInput(initValue);
  }, [initValue]);

  const doSaveRequest = (value: string) => {
    putRequest.pending();
    axios
      .put(constructApiAddress(`/v2/managers/manager`, false), {
        [property]: value,
      })
      .then((res) => {
        const {
          data: { account },
        } = res;
        updateProfile(account);
        putRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
      });
  };

  const onSave = (event: any) => {
    if (input.length < 3) {
      return setInput(profile[property]);
    }
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      if (input !== profile[property]) doSaveRequest(input);
      return;
    }
    return setInput(profile[property]);
  };

  return (
    <Flex
      row
      between
      middle
      className={[
        styles["settings-input-item"],
        styles["settings-item"],
        styles["settings-item--not-last"],
      ].join(" ")}
    >
      <InterTag
        size={14}
        text={trans(labelTransKey)}
        bold
        color={COLORS.secondary}
      />
      {putRequest.isLoading ? (
        <Loader size="xs" />
      ) : (
        <InlineEdit
          className="inter"
          value={input}
          onChange={setInput}
          onCancel={() => setInput(profile[property])}
          onSave={onSave}
          disabled={putRequest.isLoading}
        />
      )}
    </Flex>
  );
};

interface iChangePasswordProps {}

const ChangePassword: React.FC<iChangePasswordProps> = () => {
  const { trans } = useLocalizationState();
  const [passwordModal, setPasswordModal] = useState(false);
  return (
    <>
      <ChangePasswordModal
        open={passwordModal}
        onClose={() => setPasswordModal(false)}
      />
      <Flex
        row
        between
        middle
        className={[
          styles["settings-input-item"],
          styles["settings-item"],
        ].join(" ")}
      >
        <InterTag
          size={14}
          text={trans("general.password")}
          bold
          color={COLORS.secondary}
        />
        <Button onClick={() => setPasswordModal(true)} appearance="ghost">
          {trans("general.change")}
        </Button>
      </Flex>
    </>
  );
};

const PersonalSettingsWrapped: React.FC = () => {
  return (
    <div>
      <Change property="name" labelTransKey="general.name" />
      <Change property="email" labelTransKey="general.email" />
      <ChangePassword />
    </div>
  );
};

const PersonalSettings: React.FC = () => {
  return <PersonalSettingsWrapped />;
};

export default PersonalSettings;
