import { dropRight, isString } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Input, InputGroup } from "rsuite";
import { ReactComponent as KeyboardArrowRightIcon } from "../../assets/icons/keyboard_arrow_right.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as TuneIcon } from "../../assets/icons/tune.svg";
import useHotelSpacesState from "../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import useScreenSize from "../../hooks/useScreenSize";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import SkeletonText from "../Skeleton/SkeletonText";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";

interface iSearchBarProps {}

const SearchBar: React.FC<iSearchBarProps> = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const { findSpace, findSpaceByName, findSpaceAggregate, findSpaceAggregateByName } =
    useHotelSpacesState();
  const [searchText, setSearchText] = useState("");

  const onSearch = (e: any) => {
    e.preventDefault();
    const text = searchText.trim();
    const space = findSpace(text) || findSpaceByName(text);
    if (space) {
      navigate(`/spaces/${space._id}`);
    }

    const spaceAggregate = findSpaceAggregate(text) || findSpaceAggregateByName(text);
    if (spaceAggregate) {
      navigate(`/space-aggregates/${spaceAggregate._id}`);
    }
  };

  return (
    <div style={{ width: "350px" }}>
      <form>
        <InputGroup size="md">
          <Input
            onChange={setSearchText}
            value={searchText}
            placeholder={trans("Search for spaces")}
          />
          <InputGroup.Button onClick={onSearch}>
            <Icon Element={SearchIcon} fill={COLORS.secondary} size={24} />
          </InputGroup.Button>
        </InputGroup>
        <input type="submit" onClick={onSearch} style={{ display: "none" }} />
      </form>
    </div>
  );
};

interface iProps {
  loading?: boolean;
  page: string | iTextProps;
  searchbar?: boolean;
  parentPages?: { transKey: string; to: string }[];
  hideSettingsButton?: boolean;
  onRender?: () => void;
}

const PageNameTopBar: React.FC<iProps> = ({
  loading,
  page,
  parentPages,
  searchbar = true,
  hideSettingsButton = false,
  onRender,
}) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const { outlet } = useScreenSize();

  useEffect(() => {
    if (outlet) onRender && onRender();
  }, [onRender, outlet]);

  const [textSize, iconsSize, containerHeight, containerStyles, innerContainerStyles] = isMobile
    ? [20, 24, 50, { left: 0, right: 0 }, { padding: "0 16px" }]
    : [24, 24, 60, {}, {}];

  if (!outlet)
    return (
      <div
        style={{
          height: `${containerHeight}px`,
          width: "100%",
          zIndex: 2,
          backgroundColor: COLORS.app_background,
        }}
      ></div>
    );

  const { width } = outlet;

  const icons = [
    {
      label: "Settings",
      Element: TuneIcon,
      onClick: () => {
        navigate("/settings");
      },
      hide: hideSettingsButton,
    },
  ]
    .filter((e) => !e.hide)
    .map(({ Element, onClick, label }) => (
      <IconButton
        key={label}
        onClick={onClick}
        size="xs"
        appearance="subtle"
        circle
        icon={<Icon Element={Element} size={iconsSize} fill={COLORS.secondary} />}
      />
    ));

  const pageNameJSX = page ? (
    (() => {
      if (loading) return <SkeletonText width={150} height={textSize} />;

      const props = isString(page) ? { text: trans(page) } : toTextProps(page);
      return <InterTag size={textSize} bold color={COLORS.secondary} {...{ ...props }} />;
    })()
  ) : (
    <div />
  );

  const title =
    parentPages && parentPages.length ? (
      <Flex gap={8} center column style={{ height: "100%" }}>
        <Flex row gap={6} middle>
          {dropRight(
            parentPages.flatMap(({ to, transKey }) => [
              <InterTag
                key={to}
                hoverUnderline
                onClick={() => {
                  navigate(to);
                }}
                text={trans(transKey)}
                size={12}
                color={COLORS.secondary}
              />,
              <Icon Element={KeyboardArrowRightIcon} size={16} />,
            ]),
            1
          )}
        </Flex>
        {pageNameJSX}
      </Flex>
    ) : (
      pageNameJSX
    );

  return (
    <div
      style={{
        height: `${containerHeight}px`,
        width: "100%",
        // zIndex: 3,
        backgroundColor: COLORS.app_background,
      }}
    >
      <div
        style={{
          width: `${width}px`,
          height: `${containerHeight}px`,
          position: "fixed",
          zIndex: 3,
          backgroundColor: COLORS.app_background,
          top: 0,
          ...containerStyles,
        }}
      >
        <Flex row between middle style={{ height: "100%", ...innerContainerStyles }}>
          {title}
          <Flex row gap={20} middle>
            {/* {searchbar && <SearchBar />} */}
            {icons.length > 0 && (
              <Flex row gap={40}>
                {icons}
              </Flex>
            )}
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default PageNameTopBar;
