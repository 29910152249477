import axios from "axios";
import { round } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Col, ColProps } from "rsuite";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import BasicCard from "../../../../components/Cards/BasicCard";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import { tIconElement } from "../../../../interfaces/icon";
import { tHotelId, tHotelSpaceId } from "../../../../models/hotel";
import { apiAddress } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";

type tOccupancyData = {
  numberOfGuests: number;
  occupancy: number;
  spacesOccupied: tHotelSpaceId[];
};

interface iProps {
  period: [Date, Date];
  colBreakpoints: Partial<ColProps>;
}

const OccupancyCard: React.FC<iProps> = ({ period, colBreakpoints }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId, activeSubscriptionIsStarter } = useHotelSubscriptionState();

  const occupancyRequest = useGetRequest<tOccupancyData>({
    numberOfGuests: 0,
    occupancy: 0,
    spacesOccupied: [],
  });
  const homologueOccupancyRequest = useGetRequest<tOccupancyData>({
    numberOfGuests: 0,
    occupancy: 0,
    spacesOccupied: [],
  });

  const runOccupancy = useCallback(
    (
      request: tUseGetRequest<tOccupancyData>,
      hotelId: tHotelId,
      ...period: [MomentInput, MomentInput]
    ) => {
      if (activeSubscriptionIsStarter) return;
      request.pending();
      axios
        .get(
          `${apiAddress(
            false
          )}/v2/hotels/${hotelId}/occupancy/guests-spaces-occupancy`,
          {
            params: {
              from: moment(period[0]).toISOString(),
              to: moment(period[1]).toISOString(),
            },
          }
        )
        .then((res) => {
          const {
            data: { data },
          } = res;
          request.resolve(data);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error);
        });
    },
    [activeSubscriptionIsStarter, trans]
  );

  const runOccupancyMain = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runOccupancy(occupancyRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  const runOccupancyHomologue = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runOccupancy(homologueOccupancyRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelId) {
      const periodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).toISOString(),
        moment(period[1]).toISOString(),
      ];
      const homologuePeriodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).subtract(1, "year").toISOString(),
        moment(period[1]).subtract(1, "year").toISOString(),
      ];

      runOccupancyMain(hotelId, ...periodArgs);
      runOccupancyHomologue(hotelId, ...homologuePeriodArgs);
    }
  }, [hotelId, period, trans]);

  const list: {
    key: keyof Pick<tOccupancyData, "numberOfGuests" | "occupancy">;
    label: string;
    icon: tIconElement;
    to: string;
    transformer(value: number): number;
    unit?: string;
  }[] = [
    {
      key: "numberOfGuests",
      label: trans("general.guests"),
      icon: GroupIcon,
      to: "/guests",
      transformer: (v) => v,
    },
    {
      key: "occupancy",
      label: trans("general.occupancy"),
      icon: HotelIcon,
      to: "/occupancy",
      transformer: (v) => v * 100,
      unit: "%",
    },
  ];

  return (
    <>
      {list.map(({ key, label, to, icon, transformer, unit }) => {
        return (
          <Col key={key} {...{ ...colBreakpoints }}>
            <BasicCard
              hover
              onClick={() => {
                navigate(to);
              }}
              {...{
                icon,
                label,
                ...(occupancyRequest.isLoading
                  ? { valueLoading: true, value: "", comparisonLoading: true }
                  : {
                      value: `${round(
                        transformer(occupancyRequest.data[key]),
                        2
                      )}${unit ? ` ${unit}` : ""}`,

                      ...(homologueOccupancyRequest.isLoading
                        ? {
                            comparisonLoading: true,
                          }
                        : {
                            comparison: {
                              baseValue: homologueOccupancyRequest.data[key],
                              value: occupancyRequest.data[key],
                            },
                          }),
                    }),
              }}
            />
          </Col>
        );
      })}
    </>
  );
};

export default OccupancyCard;
