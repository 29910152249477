import axios from "axios";
import { sum } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useCallback, useContext, useEffect } from "react";
import Flex from "../../../../components/Flex";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../../hooks/apiRequests/useGetRequest";
import usePeriodState from "../../../../hooks/usePeriodState";
import { tBinUnitSingular } from "../../../../interfaces/sensorData";
import { tHotelId } from "../../../../models/hotel";
import { constructApiAddress } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { OccupancyContext } from "../context";
import OccupancyHeatmap from "./heatmap";

export type tData = {
  perUnitOfTime: {
    guests: number[];
    stays: number[];
    occupancyRate: number[];
  };
  total: { guests: number; stays: number; occupancyRate: number };
};

const binUnit: tBinUnitSingular = "day";
const binValue: number = 1;

const initialState: tData = {
  perUnitOfTime: { guests: [], stays: [], occupancyRate: [] },
  total: { guests: 0, stays: 0, occupancyRate: 0 },
};

const Manual: React.FC<{
  dataTypeToggle: JSX.Element;
}> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const { setDataTypeToggleDisabled } = useContext(OccupancyContext);
  const [period, setPeriod] = usePeriodState();
  const { hotelId, hotel } = useHotelState();
  const occupancyRequest = useGetRequest<tData>(initialState);
  const homologueOccupancyRequest = useGetRequest<tData>(initialState);

  const totalNumberOfRooms = hotel.spaces.filter(
    (s) => s.isAccommodation
  ).length;

  const run = useCallback(
    (
      request: tUseGetRequest<tData>,
      hotelId: tHotelId,
      from: MomentInput,
      to: MomentInput
    ) => {
      request.pending();
      axios
        .get(
          constructApiAddress(
            `/v2/hotels/${hotelId}/occupancy/guests-stays`,
            false
          ),
          {
            params: {
              from: moment(from).toISOString(),
              to: moment(to).toISOString(),
              binUnit,
              binValue,
            },
          }
        )
        .then((res) => {
          const {
            data: { perUnitOfTime, total },
          } = res;

          perUnitOfTime.occupancyRate = perUnitOfTime.stays.map((v: number) =>
            totalNumberOfRooms === 0 ? 0 : (100 * v) / totalNumberOfRooms
          );

          total.occupancyRate =
            perUnitOfTime.occupancyRate.length === 0
              ? 0
              : sum(perUnitOfTime.occupancyRate) /
                perUnitOfTime.occupancyRate.length;

          request.resolve({ perUnitOfTime, total });
        })
        .catch((err) => {
          request.reject(getErrorMessage(err, trans));
        });
    },
    [totalNumberOfRooms, trans]
  );

  useEffect(() => {
    if (hotelId && period) {
      run(occupancyRequest, hotelId, period[0], period[1]);
      run(
        homologueOccupancyRequest,
        hotelId,
        moment(period[0]).subtract(1, "year"),
        moment(period[1]).subtract(1, "year")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId, period, run]);

  useEffect(() => {
    setDataTypeToggleDisabled({ occupancyRequest: occupancyRequest.isLoading });
  }, [occupancyRequest.isLoading, setDataTypeToggleDisabled]);
  useEffect(() => {
    setDataTypeToggleDisabled({
      homologueOccupancyRequest: homologueOccupancyRequest.isLoading,
    });
  }, [homologueOccupancyRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8} middle>
          {dataTypeToggle}
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
        </Flex>
      </SecondaryTopBar>
      <Flex column>
        {period ? (
          <OccupancyHeatmap
            {...{
              occupancyRequest,
              homologueOccupancyRequest,
              binValue,
              binUnit,
              from: period[0],
              to: period[1],
            }}
          />
        ) : null}
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default Manual;
