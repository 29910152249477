import { forwardRef } from "react";
import { Button, ButtonProps } from "rsuite";
import { tIcon, tIconElement } from "../../../interfaces/icon";
import Flex from "../../Flex";
import Icon from "../../Icons/Icon";
import { toIconProps } from "../../Icons/Icon/utils";
import InterTag, { iInterTagProps } from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

export interface iSimpleButtonProps extends ButtonProps {
  text?: string | iInterTagProps;
  icon?: tIcon | tIconElement;
  iconPosition?: "left" | "right";
}

const SimpleButton = forwardRef<HTMLButtonElement, iSimpleButtonProps>(
  ({ text, icon, iconPosition = "right", ...props }, ref) => {
    const textElement = text ? <InterTag {...toTextProps(text)} /> : null;
    const iconElement = icon ? <Icon {...{ ...toIconProps(icon) }} /> : null;
    return (
      <Button {...{ ...props, ref }}>
        <Flex
          row
          between
          middle
          gap={4}
          {...{
            style: {
              ...(props.loading ? { visibility: "hidden" } : {}),
            },
          }}
        >
          {iconPosition === "left" && iconElement}
          {textElement}
          {iconPosition === "right" && iconElement}
        </Flex>
      </Button>
    );
  }
);

export default SimpleButton;
