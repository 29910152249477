import React from "react";
import { Tooltip, Whisper } from "rsuite";
import { ReactComponent as WarningIcon } from "../../../../../assets/icons/warning.svg";
import { COLORS } from "../../../../../utils/colors";
import Icon from "../../../../Icons/Icon";
import { iTextProps } from "../../../../Text";
import { toTextProps } from "../../../../Text/utils";
import BaseCell from "../BaseCell";

interface iProps {
  rowData?: Record<string, any>;
  dataKey: string;
  rowIndex?: number;

  message: string | iTextProps;
}

const SimpleErrorCell: React.FC<iProps> = ({ message, ...props }) => {
  const messageProps = toTextProps(message);

  return (
    <BaseCell {...{ ...props }}>
      <Whisper
        trigger={"hover"}
        placement="topStart"
        speaker={<Tooltip>{messageProps.text}</Tooltip>}
      >
        <div>
          <Icon Element={WarningIcon} size={20} fill={COLORS.error} />
        </div>
      </Whisper>
    </BaseCell>
  );
};

export default SimpleErrorCell;
