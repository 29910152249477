import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as MicrosoftIcon } from "../../../assets/icons/microsoft.svg";
import { ReactComponent as MicrosoftMonoIcon } from "../../../assets/icons/microsoft_mono.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityOn } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/icons/visibility_off.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import { toIconProps } from "../../../components/Icons/Icon/utils";
import InputWrapper from "../../../components/InputWrapper";
import NoAuthDesktopWrapper from "../../../components/Pages/NoAuthWrapper/noAuthDesktopWrapper";
import NoAuthMobileWrapper from "../../../components/Pages/NoAuthWrapper/noAuthMobileWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import SimpleColoredButton from "../../../components/RsuiteWrapper/SimpleColoredButton";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tIcon, tIconElement } from "../../../interfaces/icon";
import { constructApiAddress } from "../../../utils/apiCall";
import { loginRequest } from "../../../utils/azure/authconfig";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { openNewTab, preventDefault } from "../../../utils/others";

const buildTransKey = (key: string) => `pages.login.${key}`;

const LoginWrapped1: React.FC = () => {
  const { isTablet } = useWindowSizeState();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  const { login } = useAuthDispatch();
  const { setProfile } = useProfileDispatch();
  const { profileLoaded } = useProfileState();

  const [emailState, setEmailState] = useState<{
    value: string;
    error: string;
  }>({ value: "", error: "" });
  const [passwordState, setPasswordState] = useState<{
    value: string;
    error: string;
  }>({ value: "", error: "" });
  const [visibility, setVisibility] = useState(false);
  const postRequest = usePostRequest();
  const { instance } = useMsal();

  const [preToken, setPreToken] = useState<null | string>(null);

  useEffect(() => {
    if (preToken && profileLoaded) login(preToken);
  }, [preToken, profileLoaded, login]);

  const onClickForgot = () => {
    navigate("/forgot-password");
  };

  const onClickLogin = () => {
    let error = false;
    if (!emailState.value) {
      setEmailState((prev) => ({
        ...prev,
        error: trans("Enter a valid email"),
      }));
      error = true;
    }

    if (error) return;

    postRequest.pending();
    axios
      .post(constructApiAddress(`/v2/managers/login`, false), {
        email: emailState.value,
        password: passwordState.value,
      })
      .then((res) => {
        const {
          data: { token, account },
        } = res;

        setProfile(account);
        setPreToken(token);
        postRequest.resolve({}, trans(buildTransKey("notifications.logged_in")));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  const renderGreeting = (size: number) => (
    <Flex gap={8} column middle>
      <InterTag
        wrap
        bold
        text={trans(buildTransKey("greetings[0]"))}
        size={size}
        color={COLORS.secondary}
      />
      <InterTag
        style={{ textAlign: "center" }}
        bold
        wrap
        text={trans(buildTransKey("greetings[1]"))}
        size={size}
        color={COLORS.secondary}
      />
    </Flex>
  );

  const renderForm = () => (
    <form onSubmit={preventDefault(onClickLogin)}>
      <Flex column gap={16}>
        <InputWrapper required label={trans("general.email")} error={emailState.error}>
          <InputGroup>
            <InputGroup.Addon>
              <Icon Element={MailIcon} width={24} height={24} fill={COLORS.secondary} />
            </InputGroup.Addon>
            <Input
              value={emailState.value}
              onChange={(value) => setEmailState({ value, error: "" })}
              type="text"
              placeholder={trans("general.placeholders.email")}
              size="lg"
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper required label={trans("general.password")} error={passwordState.error}>
          <InputGroup>
            <InputGroup.Addon>
              <Icon Element={PasswordIcon} width={24} height={24} fill={COLORS.secondary} />
            </InputGroup.Addon>
            <Input
              value={passwordState.value}
              onChange={(value) => setPasswordState({ value, error: "" })}
              type={visibility ? "text" : "password"}
              placeholder={trans("MySecureP@ssw0rd")}
              size="lg"
            />
            <InputGroup.Button
              className={`hover-gray-100 bg-white`}
              onClick={() => setVisibility((prev) => !prev)}
            >
              {visibility ? (
                <Icon Element={VisibilityOn} width={24} height={24} fill={COLORS.gray} />
              ) : (
                <Icon Element={VisibilityOff} width={24} height={24} fill={COLORS.gray} />
              )}
            </InputGroup.Button>
          </InputGroup>
        </InputWrapper>
        <Flex right style={{ marginTop: "4px" }}>
          <InterTag
            onClick={onClickForgot}
            hoverUnderline
            text={trans(buildTransKey("forgot_password"))}
            color={COLORS.primary}
            size={16}
          />
        </Flex>
      </Flex>
      <input type="submit" hidden />
    </form>
  );

  const renderSignup = (color: string) => (
    <Flex row gap={4} center>
      <InterTag color={color} text={trans(buildTransKey("goto_signup[0]"))} />
      <InterTag
        color={COLORS.primary}
        text={trans(buildTransKey("goto_signup[1]"))}
        hoverUnderline
        onClick={() => {
          navigate("/register");
        }}
      />
      <InterTag color={color} text={trans(buildTransKey("goto_signup[2]"))} />
    </Flex>
  );

  const renderLoginButton = () => (
    <SimpleButton
      loading={postRequest.isLoading}
      onClick={onClickLogin}
      text={{
        text: trans("general.signin"),
        bold: true,
        color: COLORS.white,
        size: 20,
      }}
      appearance="primary"
    />
  );

  const handleAzureLogin = () => {
    postRequest.pending();
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        axios
          .post(constructApiAddress("/v2/managers/login-azure", true), {
            ...res,
          })
          .then((res) => {
            const {
              data: { token, account },
            } = res;

            setProfile(account);
            setPreToken(token);
            postRequest.resolve({}, trans(buildTransKey("notifications.logged_in")));
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            postRequest.reject(error, true);
          });
      })
      .catch((e) => {
        console.error(e);
        postRequest.reject("");
      });
  };

  const bottomAnchors: { label: string; onClick(): void }[] = [
    {
      label: "general.terms_and_conditions",
      onClick() {
        openNewTab("https://noytrall.com/en/policies/terms-and-conditions");
      },
    },
    {
      label: "general.privacy_policy",
      onClick() {
        openNewTab("https://noytrall.com/en/policies/privacy-policy");
      },
    },
  ];

  const renderConnectedAccountsButtons = (azure: {
    icon: tIconElement | tIcon;
    textColor: string;
    buttonColor: string;
  }) => {
    return (
      <Flex column middle>
        <div style={{ width: "fit-content" }}>
          <SimpleColoredButton
            onClick={handleAzureLogin}
            color={azure.buttonColor}
            iconPosition="left"
            icon={{
              ...toIconProps(azure.icon),
              size: 24,
            }}
            text={{
              text: trans(buildTransKey("signin_with_microsoft")),
              size: 16,
              color: azure.textColor,
            }}
          />
        </div>
      </Flex>
    );
  };

  if (isTablet)
    return (
      <NoAuthDesktopWrapper>
        <Flex column middle gap={16}>
          <Flex
            column
            center
            style={{
              backgroundColor: COLORS.light_white,
              borderRadius: "16px",
              padding: "32px",
              minWidth: "400px",
              maxWidth: "400px",
            }}
          >
            <Flex column gap={28}>
              {renderGreeting(24)}
              <div style={{ width: "100%" }}>{renderForm()}</div>
              {renderLoginButton()}
              <Flex row gap={12} middle>
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    width: "100%",
                    backgroundColor: COLORS.gray_300,
                  }}
                />
                <InterTag text={trans("general.or")} size={16} color={COLORS.gray_500} />
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    width: "100%",
                    backgroundColor: COLORS.gray_300,
                  }}
                />
              </Flex>
              {renderConnectedAccountsButtons({
                icon: MicrosoftIcon,
                buttonColor: COLORS.secondary,
                textColor: COLORS.white,
              })}
            </Flex>
          </Flex>
          {renderSignup(COLORS.white)}
          <Flex row gap={16}>
            {bottomAnchors.map((elem) => (
              <InterTag
                key={elem.label}
                size={12}
                text={trans(elem.label)}
                onClick={elem.onClick}
                hoverUnderline
                color={COLORS.gray_300}
              />
            ))}
          </Flex>
        </Flex>
      </NoAuthDesktopWrapper>
    );

  return (
    <NoAuthMobileWrapper>
      <Flex column style={{ marginTop: "80px", padding: "0 20px" }} gap={32}>
        {renderGreeting(24)}
        <div style={{ padding: "0 8px" }}>{renderForm()}</div>
        <Flex column style={{ padding: "0 8px" }}>
          {renderLoginButton()}
        </Flex>
        {renderConnectedAccountsButtons({
          icon: { Element: MicrosoftMonoIcon, fill: COLORS.white },
          textColor: COLORS.white,
          buttonColor: COLORS.water,
        })}
        {renderSignup(COLORS.secondary)}
      </Flex>
    </NoAuthMobileWrapper>
  );
};

const Login: React.FC = () => {
  return <LoginWrapped1 />;
};

export default Login;
