import { round } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, ColProps } from "rsuite";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import BasicCard from "../../../../components/Cards/BasicCard";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { tIconElement } from "../../../../interfaces/icon";
import { tWithRequired } from "../../../../interfaces/others";
import { tHotelManualData } from "../../../../models/hotel";

interface iProps {
  manualDataEntry?: tHotelManualData;
  colBreakpoints: Partial<ColProps>;
}

const OccupancyCard: React.FC<iProps> = ({
  manualDataEntry,
  colBreakpoints,
}) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { findPreviousAvailableYearManualDataEntry } =
    useHotelManualDataState();

  if (!manualDataEntry) return null;

  const { occupancy } = manualDataEntry;

  if (!occupancy) return null;

  const homologueEntry = findPreviousAvailableYearManualDataEntry(
    manualDataEntry,
    "occupancy"
  );

  const list: {
    key: keyof tWithRequired<tHotelManualData, "occupancy">["occupancy"];
    label: string;
    icon: tIconElement;
    to: string;
    unit?: string;
  }[] = [
    {
      key: "numberOfGuests",
      label: trans("Guests"),
      icon: GroupIcon,
      to: "/guests",
    },
    {
      key: "occupancyRate",
      label: trans("general.occupancy"),
      icon: HotelIcon,
      to: "/occupancy",
      unit: "%",
    },
  ];

  return (
    <>
      {list.map(({ key, label, to, icon, unit }) => {
        return (
          <Col key={key} {...{ ...colBreakpoints }}>
            <BasicCard
              hover
              onClick={() => {
                navigate(to);
              }}
              {...{
                icon,
                label,

                value: `${round(manualDataEntry.occupancy![key], 2)}${
                  unit ? ` ${unit}` : ""
                }`,

                ...(homologueEntry && homologueEntry.occupancy
                  ? {
                      comparison: {
                        baseValue: homologueEntry.occupancy![key],
                        value: manualDataEntry.occupancy![key],
                      },
                    }
                  : {}),
              }}
            />
          </Col>
        );
      })}
    </>
  );
};

export default OccupancyCard;
