import axios from "axios";
import moment, { MomentInput } from "moment";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Col, ColProps } from "rsuite";
import { ReactComponent as DirectionsWalkIcon } from "../../../assets/icons/directions_walk.svg";
import { ReactComponent as EmojiPeopleIcon } from "../../../assets/icons/emoji_people.svg";
import BasicCard from "../../../components/Cards/BasicCard";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tIconElement } from "../../../interfaces/icon";
import { tHotelId } from "../../../models/hotel";
import { constructApiAddress } from "../../../utils/apiCall";
import { getErrorMessage } from "../../../utils/httpResponses/others";

type tCheckInData = {
  checkIn: { total: number; toDate: number };
  checkOut: { total: number; toDate: number };
};

interface iProps {
  period: [Date, Date];
  colBreakpoints?: Partial<ColProps>;
}

const CheckInCheckOut: React.FC<iProps> = ({ period, colBreakpoints }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId, activeSubscriptionIsStarter } = useHotelSubscriptionState();

  const checkInRequest = useGetRequest<tCheckInData>({
    checkIn: { total: 0, toDate: 0 },
    checkOut: { total: 0, toDate: 0 },
  });
  const homologueCheckInRequest = useGetRequest<tCheckInData>({
    checkIn: { total: 0, toDate: 0 },
    checkOut: { total: 0, toDate: 0 },
  });

  const runCheckIn = useCallback(
    (
      request: tUseGetRequest<tCheckInData>,
      hotelId: tHotelId,
      ...period: [MomentInput, MomentInput]
    ) => {
      if (activeSubscriptionIsStarter) return;

      request.pending();
      axios
        .get(
          constructApiAddress(
            `/v2/hotels/${hotelId}/occupancy/check-in-out`,
            false
          ),
          {
            params: {
              from: moment(period[0]).toISOString(),
              to: moment(period[1]).toISOString(),
            },
          }
        )
        .then((res) => {
          const {
            data: {
              data: { checkIn, checkOut },
            },
          } = res;
          request.resolve({ checkIn, checkOut });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error);
        });
    },
    [activeSubscriptionIsStarter, trans]
  );

  const runCheckInMain = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runCheckIn(checkInRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  const runCheckInHomologue = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runCheckIn(homologueCheckInRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelId) {
      const periodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).toISOString(),
        moment(period[1]).toISOString(),
      ];
      const homologuePeriodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).subtract(1, "year").toISOString(),
        moment(period[1]).subtract(1, "year").toISOString(),
      ];

      runCheckInMain(hotelId, ...periodArgs);
      runCheckInHomologue(hotelId, ...homologuePeriodArgs);
    }
  }, [hotelId, period, trans]);

  const list: { key: keyof tCheckInData; label: string; icon: tIconElement }[] =
    [
      {
        key: "checkIn",
        label: trans(
          "pages.overview.sections.summary.cards.checkInOut.label_checkIn"
        ),
        icon: DirectionsWalkIcon,
      },
      {
        key: "checkOut",
        label: trans(
          "pages.overview.sections.summary.cards.checkInOut.label_checkOut"
        ),
        icon: EmojiPeopleIcon,
      },
    ];

  return (
    <>
      {list.map(({ key, label, icon }) => {
        return (
          <Col key={key} {...{ ...colBreakpoints }}>
            <BasicCard
              hover
              onClick={() => {
                navigate("/guests");
              }}
              {...{
                icon,
                label,
                ...(checkInRequest.isLoading
                  ? { valueLoading: true, value: "", comparisonLoading: true }
                  : {
                      value: `${checkInRequest.data[key].toDate}/${checkInRequest.data[key].total}`,
                      ...(homologueCheckInRequest.isLoading
                        ? {
                            comparisonLoading: true,
                          }
                        : {
                            comparison: {
                              baseValue:
                                homologueCheckInRequest.data[key].total,
                              value: checkInRequest.data[key].total,
                            },
                          }),
                    }),
              }}
            />
          </Col>
        );
      })}
    </>
  );
};

export default CheckInCheckOut;
